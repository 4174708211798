import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/madman-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 slasher movie, Madman"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 12</h1>
                    <h2>Madman</h2>
                    <h3>July 10, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/madman">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:08.256)
I believe we&#39;re on. You&#39;re listening to Bring Me the Axe. I&#39;m Brian White, one half of this morbid equation. I&#39;m joined by my cohost and actual brother, Dave White. Dave, how you doing?</p>
<p>Dave! (00:20.955)
Hello. I did. I liked that.</p>
<p>Bryan! (00:22.845)
Yes, yes, it&#39;s a somber occasion. Did you notice what I did there at the very beginning though? Right, right new name new name. Yep. This is the first is the first the first show We&#39;re actually doing that so we are Formerly Brothers Grimm. We&#39;re bring me the axe. It&#39;s just a better name</p>
<p>Dave! (00:40.43)
That reminds me of Faye Dunaway every time.</p>
<p>Bryan! (00:45.632)
You gotta have a daily reminder of the time Faye Donahue played Joan Crawford.</p>
<p>Dave! (00:50.309)
You know that time Faye Dunaway was a total lunatic? I mean, I guess that&#39;s kind of every day when you&#39;re Faye Dunaway, but...</p>
<p>Bryan! (00:53.116)
Hahaha</p>
<p>Bryan! (00:59.128)
Yep. So we practically grew up in neighborhood video stores and the steady diet of utter garbage that those shops provided us with continues unabated to this day. And there&#39;s no one else I enjoy chopping it up with about trashy movies more than Dave. And so just before we get into it, here&#39;s a little housekeeping. You wanna keep up with us between episodes. You can also find us pretty much on all the socials by just searching Bring Me the Axe Horror Podcast. We&#39;ve also got a sweet website now at bringmethiaxe.com. You can listen to all of our past shows there and read the transcripts.</p>
<p>You can also contact us directly at bringmetheaxpod at gmail.com with any questions, comments, or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give it the business. And lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts, and you&#39;d be doing us a favor by leaving us a five-star review on Apple podcasts. Just wanted to get all that out of the way right at the top of the show. So...</p>
<p>Dave! (01:49.923)
Let&#39;s fucking tell people about it, come on!</p>
<p>Bryan! (01:52.7)
Yeah, I know. I mean, Jesus Christ. We got a big bump recently, and like, I know a lot of you are listening, so fucking tell your friends. So yeah, tonight&#39;s movie. Let me give you a taste.</p>
<p>Bryan! (03:47.252)
There is no escape. Yep, tonight&#39;s movie is Madman. So a warning before we get rolling, we&#39;re basically going to talk about this movie from beginning to end, so spoilers to follow. Yeah, some facts about this, as usual. The year was 1981.</p>
<p>Dave! (04:08.949)
Ah, yeah, it&#39;s good here.</p>
<p>Bryan! (04:10.268)
Yep, and some other movies released that year were Demonoid, Absurd, which I just recently watched. Yeah, yeah. I don&#39;t know if anybody really knew what they were doing. They just had like George Eastman for a weekend. It&#39;s not a good movie.</p>
<p>Dave! (04:27.644)
Like that&#39;s how it&#39;s just how you&#39;re going to get Georgie&#39;s mental.</p>
<p>Bryan! (04:30.228)
Probably, yeah. Also, Final Exam came out that year. Go back and check out our Final Exam episode. Just before dawn also came out in 81. One of the coolest endings that I was not prepared for. Also, Scanners with Cronenberg. And lastly, Porno Holocaust. I&#39;ve never actually seen it. I love the name.</p>
<p>Dave! (04:41.974)
One of the coolest endings ever.</p>
<p>Dave! (04:54.488)
Oh, yikes.</p>
<p>Bryan! (04:59.764)
That&#39;s about as much of that movie as I want to take in though. Yeah</p>
<p>Dave! (05:02.242)
Do you know what it&#39;s part of that like genre? I don&#39;t even, it&#39;s not a genre, it&#39;s like a sub genre of like softcore, somewhere between hardcore and softcore porn and horror, which is like, here are two great tastes that do not taste great together.</p>
<p>Bryan! (05:19.268)
No, no, that&#39;s not something, it&#39;s like putting like clam chowder in a bowl of corn flakes together, you know? It&#39;s like, yeah, I don&#39;t, I don&#39;t, I don&#39;t know. Anybody who&#39;s into that, I find them sus. Not my scene, no.</p>
<p>Dave! (05:31.534)
Yeah. Great score to that movie, though, by Nico Fidenko.</p>
<p>Bryan! (05:36.092)
Oh yeah? Not bad, not bad. I know, what is it? Mike Patton has an album, I believe it&#39;s called Torn to Holocaust. It&#39;s one of those ones where it&#39;s just him screaming into a microphone.</p>
<p>Dave! (05:45.71)
shrieking and banging on a suitcase.</p>
<p>Bryan! (05:48.06)
Yeah, so cast and crew, this is rather anemic as we&#39;re gonna as we&#39;re gonna discover here The director is a guy named Joe Gennone who to the best of my understanding made this movie and this movie only He had a second 80 credit on some other movie that I can&#39;t remember the name of and that&#39;s it. No, no, dude just vanished No idea where he is today Do they cover it in any of the extras? Oh?</p>
<p>Dave! (06:11.65)
He&#39;s dead. He&#39;s dead.</p>
<p>He died in like 2006.</p>
<p>Bryan! (06:17.196)
Oh, well, OK. I mean, I thought you meant like the explanation was he died in the 80s, and that&#39;s why we never got any more from him. No, this is like a Joe Gennoni and a Gary Sails production. And it seems like Gary.</p>
<p>Dave! (06:29.718)
Gary Sales, Gary Sales first AD on From Justin to Kelly. Yeah? That guy&#39;s got a weird, weird career.</p>
<p>Bryan! (06:34.812)
No shit. Huh, can I look them up and he&#39;s got a, yeah, he&#39;s got like, he&#39;s got a bunch of producer credits, but like a lot of the people involved in this movie, they&#39;re, it&#39;s like, basically it&#39;s their very first professional credit that&#39;s on IMDB, and then it&#39;s either their last credit, or there&#39;s like a 15 year gap or 20 year gap before they were working again. And they all ended up.</p>
<p>Dave! (06:59.65)
Well, it turns out a lot of them actually weren&#39;t and aren&#39;t actors. Yeah, so the</p>
<p>Bryan! (07:04.552)
Really? Well, I mean, it&#39;s kind of easy to tell with some of them, but...</p>
<p>Dave! (07:08.146)
So the guy who plays Madman Mars, he was like doing the. Yeah, he was like doing the poster art, like for the one sheet. And he I guess his version of the story is like he was trying to describe to them what the killer would be doing. And they were like, why don&#39;t you just play the killer? Like you&#39;re giant menacing. And it&#39;s like, OK, well, it&#39;s not charming. But so he did. And the guy who plays Dippy.</p>
<p>Bryan! (07:12.186)
Yeah, he was a graphic designer.</p>
<p>Bryan! (07:31.741)
Yeah.</p>
<p>Bryan! (07:36.084)
Yes!</p>
<p>Dave! (07:36.77)
Fucking dippy that guy is a like a special effects He&#39;s yeah, he&#39;s like a puppeteer</p>
<p>Bryan! (07:40.348)
Is he like a... Oh, no shit, okay, so this explains Dippy a little bit because I sort of, yeah, I was gonna say, if you told me he went to clown college, I would have totally understood.</p>
<p>Dave! (07:51.094)
And like, so I watched a bunch of the bonus features on the vinegar syndrome, UHD release, and it did be, if you showed me a picture of this guy and gave me no context, I&#39;d be like, that guy makes puppets for a living. Like he is just, he is looking ragged and he&#39;s like in a barn in Vermont. I&#39;m like, I know lots of people like you and they all make puppets now.</p>
<p>Bryan! (07:57.501)
Yeah.</p>
<p>Bryan! (08:11.15)
Yeah.</p>
<p>Bryan! (08:16.869)
Yeah, so that&#39;s Paul Ehlers is the guy who plays madman mars and as we said he was a designer he&#39;s working on promo materials for the movie. He&#39;s a big guy he&#39;s got some athleticism to him so they were like hey why don&#39;t you play the killer. It&#39;s a suit that they put on him which is a really weird touch to me. No it&#39;s hands feet and a mask yeah it&#39;s such a weird such a weird detail for the movie.</p>
<p>Dave! (08:20.866)
Yeah.</p>
<p>Dave! (08:35.53)
It&#39;s not even really a suit though. It&#39;s hands, a mask and feet.</p>
<p>And it&#39;s so obvious, like I don&#39;t. Again, I&#39;m watching this in 4K, so maybe it looks better, you know, in 35 millimeter or whatever, but like whatever shot in. But it is so obvious that these are rubber hands and feet.</p>
<p>Bryan! (08:45.216)
Hahaha</p>
<p>Bryan! (08:56.74)
Yeah, I think that for a lot of these movies, the move to home video like really did them a lot of favors because like a crappy special effect will look significantly less crappy when it&#39;s moved over to like NTSC video standard or like, you know, any of that PAL or any of the other ones, because it gets all fucking muddy. And, you know, even like the good VHS tapes or like the very first generations that came on.</p>
<p>quality tapes and were sourced from quality materials, they still have a certain look about them. So like, I&#39;m sure that the fact that most, like almost this entire movie, there isn&#39;t a single shot in the day. When Mars shows up, he&#39;s always sort of like concealed in shadows. So it must&#39;ve looked blurry and murky and shitty. So like, it wasn&#39;t until like high-def video formats came along that we were like, oh, okay. So that&#39;s like, he&#39;s wearing like foot boots.</p>
<p>Dave! (09:55.194)
Yeah, like Fred Flintstone, fucking rubber feet. And like an amazing party city fright wig wig. It&#39;s fantastic.</p>
<p>Bryan! (09:59.9)
Yep. Man, they...</p>
<p>Bryan! (10:05.016)
I will get to him because I got notes about his appearance. I like there&#39;s a thing about Madman Mars that I think is very unique to this movie. He&#39;s something. So also Galen Ross from she you know from Dawn of the Dead. This is her second feature I believe. She&#39;s acting under a pseudonym Alexis Dubin.</p>
<p>Dave! (10:12.444)
is utterly charming.</p>
<p>Bryan! (10:30.524)
in the movie and then the reason for this, she&#39;s actually one of three people in the movie who are acting under a pseudonym. And the reason is that the movie was non-union, so SAG actors were like barred from acting in it. And I&#39;m guaranteed that like she needed some work and was like, fuck it. Like, I don&#39;t care about the credit, I&#39;ll just take the money and use a different name or something. But it&#39;s her, the guy who plays TP and the guy who plays Max were all union actors who had to...</p>
<p>take a pseudonym.</p>
<p>Dave! (11:02.755)
And the way she talks about it in these features is, like, they didn&#39;t even know each other&#39;s real names. Yeah, but like in...</p>
<p>Bryan! (11:11.252)
Weird? That must mean like, being sneaky and trying to get around sag is how it have to be like in Reservoir Dogs where they&#39;re all like, like you&#39;re Mr. White and you&#39;re Mr. Pink and nobody gets a real name so nobody can spill the beans.</p>
<p>Dave! (11:25.983)
It sounds like it because she&#39;s like, you know, they would be calling out Alexis, Alexis Dubin on set and I would have no idea who they&#39;re talking to. Like, that has got to be confusing as hell.</p>
<p>Bryan! (11:36.116)
Super weird. Super weird. But yeah, like most of the folks in this movie, she hasn&#39;t done a whole lot of acting. I mean, I&#39;m sure it&#39;s fairly well known to horror fans. I was actually kind of surprised because I was never like, I never really followed her. I met her once at one of those conventions and she signed my daughter the dead box set. She&#39;s very nice. But there was daughter the dead, there&#39;s this one and this creep show. But nowadays she does a lot of directing. It looks like she does a lot of,</p>
<p>documentaries.</p>
<p>Dave! (12:07.35)
Yeah, she&#39;s like documentary, like PBS, BBC type stuff.</p>
<p>Bryan! (12:10.888)
Yeah, yeah. Also, there&#39;s Frederick Newman. He plays Max. And I&#39;m not really sure what Max&#39;s role in the camp is. He&#39;s either like the owner or like a head counselor or something.</p>
<p>Dave! (12:21.486)
I think he&#39;s like the director. He&#39;s like the, I&#39;m the cool guy camp director. Like I know you guys have got some beer stashed. Just save one for me. Hey, hey kids.</p>
<p>Bryan! (12:28.484)
Yeah, save one for me. Yeah. So yeah, he&#39;s about one of the only other people on the cast who actually did much more than this movie. So after this, he did a lot of like episodic television and like made for TV movies. He was like, you know, like he&#39;s, I think he showed up on like Law and Order and CSI, New York and anything that&#39;s being shot in New York. So it was basically like.</p>
<p>Dave! (12:51.83)
He was like, he was part of a, like a theater troupe. I don&#39;t know if it was like avant-garde theater troupe or something, but like that&#39;s kind of his thing was like, you know, like kind of offbeat theater. And he is also in one of these documentaries and he is looking old and they all like decrepit. Like he is, it&#39;s filmed the year that he died. So he looks real bad. Yeah.</p>
<p>Bryan! (13:13.352)
Oh, okay, that was 2012, I think. Yeah, yeah, I haven&#39;t noticed, says he dies in 2012. But like, yeah, like I think in this movie, he&#39;s like kind of middle-aged to begin with. But yeah, he&#39;s an interesting character. The rest of the cast is like a lot of the people who show up in the movies that we do, they did this movie and they were never seen again. I assume that they were like, I think like a lot of people who end up in these sort of like New York production of horror movies where they were stage actors.</p>
<p>And so once this was over and like really once the slasher wave kind of crashed and most horror movies went back into production in California, most of them probably went into like TV advertisements and you know plays and cab driving. Now they&#39;re doing a lot of Uber.</p>
<p>Dave! (14:00.252)
Cab driving. It all just reminds me of the Bobby Wheeler character on Taxi, the Jeff Conway.</p>
<p>Bryan! (14:08.905)
Oh yeah. Yeah. Oh yeah. Yeah.</p>
<p>Dave! (14:11.018)
He&#39;s always trying to make it big in acting in the theater. I don&#39;t think it ever happened. And then Jeff got my, you know, things didn&#39;t work out for him.</p>
<p>Bryan! (14:14.576)
Yep. Nope. Jeff Conway, man, that&#39;s a... Things didn&#39;t really work out for him in real life either. So some notes about the movie. Trimium.</p>
<p>Dave! (14:24.254)
Wait, Eunice, don&#39;t skip over the great fact about the soundtrack to this film by Stephen Horlick.</p>
<p>Bryan! (14:31.528)
Give it to me, because I don&#39;t know it.</p>
<p>Dave! (14:33.206)
The only other thing that I think anyone would know Stephen Horlick from, if you were our age, and maybe a little bit younger, is the Reading Rainbow theme song.</p>
<p>Bryan! (14:41.929)
Oh, that&#39;s right, you told me that once before. Yep.</p>
<p>Dave! (14:44.81)
Also, I mean this whole soundtrack, fucking bangers, wall to wall, awesome.</p>
<p>Bryan! (14:49.632)
I love it. It&#39;s not your average horror movie soundtrack. It&#39;s got, I suppose we&#39;ll hit it up. There&#39;s some really good like throbbing synth stuff in it. It has a, like basically a Madman Mars theme song, which is so great. I wish more Slasher Killers had their own theme songs, but yeah, that&#39;s definitely a.</p>
<p>Dave! (15:07.31)
Yeah, it does. Killer.</p>
<p>Dave! (15:16.382)
It&#39;s like someone gave him the score to Halloween. They&#39;re like, can you do this? And he was like, well, no, but I can do something. I can mix other music that sounds like it a little bit.</p>
<p>Bryan! (15:25.764)
Yeah, right, because it&#39;s really kind of out of there&#39;s like two kind of characteristics to it. There&#39;s the goofy, silly, like, funeral march song that kind of plays over the credits. And then there&#39;s like really stingy, high, you know, high register suspense music that really works great because they like they use it a lot. It&#39;s very tense and</p>
<p>and scary when it needs a scene to sort of like get kind of pushed over the edge.</p>
<p>Dave! (15:57.794)
Then there&#39;s the hot Madman Mars Club mix at the end.</p>
<p>Bryan! (16:00.312)
Oh God, yes, a love theme from Madman. Yep, oh fuck man, that song, we&#39;ll get to that. So yeah, trivia for this movie is hard to come by. There&#39;s just not much out there to know about it. Okay, good, I&#39;m glad you do, because I don&#39;t have much. This was sort of a fly by night production, cast with a majority of players who never acted on screen again. Still very early in the slasher production wave. So common tropes hadn&#39;t yet fully been established, but even by that low standard,</p>
<p>Dave! (16:03.298)
Yeah.</p>
<p>Dave! (16:12.598)
I&#39;m gonna hit you with a bunch of facts though.</p>
<p>Bryan! (16:30.332)
Most of the cast and even the script seem to have no idea what this movie is about.</p>
<p>Dave! (16:35.242)
Well, and you know, so here&#39;s the kind of main thing about the movie is, so they, I think it was right after Friday, the first Friday the 13th came out. Obviously the thing&#39;s a fucking huge hit, runs away, makes tons of money for everybody. And so all the studios are like, we will buy anything. Does it have teenagers in it? Are they sexy? Do they get murdered? That&#39;s all it took. And so they were like, if that happens, you can make sexy teenage murder movie.</p>
<p>Bryan! (16:45.855)
Right.</p>
<p>Dave! (17:04.578)
and then the sequel and they will buy them. And so everyone was like out to make these. So they rushed to get money to make this movie that is supposedly based on the Cropsey legend, which is sort of a New York legend, which whatever. But they went out to get money and the funding got like really delayed. So by the time they finally got their money to start casting for the movie, they&#39;re getting people, well, they&#39;re getting people coming in and they&#39;re like, I just...</p>
<p>Bryan! (17:06.632)
Yeah.</p>
<p>Bryan! (17:14.928)
Right. Yeah, I&#39;ve got some stuff in here about Cropsy. We.</p>
<p>Bryan! (17:29.396)
The burning was out, right?</p>
<p>Dave! (17:33.938)
tested for another role in a movie that is exactly like this. And they were like, what the fuck are you talking about? So they look it up and it&#39;s the burning. So the burning kind of beat them to it. And they were like, all right, we have to rework this movie. And they did it in like a day or two. And let me tell you something, it shows because this movie has zero fucking plot. I don&#39;t know what the original looked like, but this movie has like nothing happening. There&#39;s no explanations or anything. You don&#39;t know why any of these people are here.</p>
<p>Bryan! (17:38.718)
Yeah.</p>
<p>Bryan! (17:51.176)
Yeah, yeah.</p>
<p>Dave! (18:02.746)
The few facts that you do get are confusing as hell. And it all adds up to being one hell of a movie.</p>
<p>Bryan! (18:11.157)
It&#39;s got charm for something that was very clearly put together, like at the last minute and like rushed into production.</p>
<p>Dave! (18:18.542)
And they wanted Vincent Price to play the role of Max, and they were like, oh, well, it&#39;s a non-sag movie, so I don&#39;t think we&#39;ll get him. And it&#39;s like, is that why you think you&#39;re not gonna get him? You don&#39;t just wave money in Vincent Price&#39;s face. You know, he&#39;s like a little bit more class than that. I mean, he did a lot of really shitty movies, but he specifically didn&#39;t do shit like this because he did not like horror movies this life.</p>
<p>Bryan! (18:22.555)
Yeah, yeah.</p>
<p>Bryan! (18:35.625)
He&#39;s a maniculture. Yeah.</p>
<p>Bryan! (18:46.732)
Right, right, yeah, like he liked the sort of gothic stuff. He liked anything that looked like it was a lot of fun. Horror, horror movies, he did not care for. Yep, hated Witchfinder General. Yeah, but you know, these, maybe that&#39;s one of those things that like they kind of came up with afterwards. Just somebody said something in an interview and they just kind of threw it in there. And it was never, like they never tried to get Vincent Price. It was just something that they started to kind of tell people.</p>
<p>Dave! (18:54.134)
Yeah, violent horror was not his thing. He hated the ones that he was in, didn&#39;t wanna do them.</p>
<p>Bryan! (19:15.432)
Maybe to kind of class up their project or something. But the Cropsy legend. Oh, yeah, I know. The Cropsy legend. So Cropsy is.</p>
<p>Dave! (19:19.118)
You&#39;re gonna need more than Vincent Price for that.</p>
<p>Bryan! (19:27.612)
Sort of a regional urban legend like a boogeyman that was kind of very specific to New York specifically Staten Island and also like New Jersey, but like</p>
<p>Dave! (19:36.854)
Well, the real the real thing is Staten Island. I think it was like it&#39;s an urban legend that kind of circulates around that area, like the Hudson Valley area.</p>
<p>Bryan! (19:45.98)
Yeah, yeah. So like all the everywhere, everywhere kind of that radiates outward from there has a version of the story, the escaped mental patient who murders people on lovers lane like they if you were if you&#39;ve ever heard that sort of a story at like a campfire, it comes from this. But where most of those stories are bullshit. The story of Cropsey is actually true, but it was</p>
<p>Dave! (20:13.294)
I don&#39;t think so though. I think it&#39;s retroactively true. Like I think it&#39;s one of those things where, because that story goes back decades before the actual documentary that you were about to talk about.</p>
<p>Bryan! (20:23.428)
Yeah, yeah, yeah. But I mean, maybe. Because the thing is, on Staten Island, there were a series of kidnappings and murders that were eventually linked up to. At the time of the production of the movie, there were still unsolved cases. But a few years later, a guy named Andre Rand was picked up, who was like an orderly. He had a bunch of roles at this.</p>
<p>mental hospital that was on Staten Island was closed. And when it was closed, they just kind of opened up the doors and let everybody wander out into the streets because they didn&#39;t know where to put them.</p>
<p>Dave! (21:00.779)
And that&#39;s not quite how it happened. But it&#39;s sorta how it happened. It&#39;s Reagan-era 80s. Like that was basically what they did.</p>
<p>Bryan! (21:08.892)
So Rand took up occupation, or he began to occupy like the forest and like a tent essentially. And they eventually linked a couple, basically there were several that they were, several disappearances that they were trying to get him on, but they could only really link him to one. And even in that case, they couldn&#39;t conclusively prove that he murdered the girl, but they definitely got him on a charge of like first degree kidnapping, which was enough to put him into jail for like.</p>
<p>something years and then right as he was about to get out they nailed him with another one that kind of stuck him in jail like he won&#39;t he&#39;ll probably die in jail but in that in that time the his name was sort of attached to the Cropsey legend and there&#39;s a really great documentary about it that I very strongly recommend it&#39;s just called Cropsey and it goes I think it&#39;s great you shut up all right we&#39;re diverting</p>
<p>Dave! (22:01.066)
And I strongly do not recommend it. We are divergent on this. Like my problem with it is that like, it&#39;s not, you know, I could go back and do that and be like, well, here&#39;s a child killer must be this. Like it&#39;s like, okay, well, yeah, I mean, this is a, it&#39;s a, that&#39;s what urban legends are. They&#39;re vague.</p>
<p>by their very nature, like to go back and try to assign a point of origin, they don&#39;t come from a specific story. That&#39;s how they evolve. So to be like, well, this is where it comes from. Not really, this is just a thing that it fits because it&#39;s so vague, because it&#39;s the thing that it&#39;s warning against. But it is still interesting to tell it because it&#39;s like, here&#39;s an actual crapsie maniac. I like that you said he takes up in the forest. I mean, he&#39;s like a hobbit.</p>
<p>Bryan! (22:48.305)
Yeah, yeah, but yeah.</p>
<p>Dave! (22:49.066)
You know, a bit if like if like Hobbits killed children. And for all for all I know, they do. I&#39;ve seen the one movie. I don&#39;t know anything else about them.</p>
<p>Bryan! (22:57.712)
Oh no no, I mean if you saw the one movie then you&#39;d know that they&#39;re jolly fellows who love to eat.</p>
<p>Dave! (23:01.846)
and they smoke pipes and they&#39;re hilarious. All right, well.</p>
<p>Bryan! (23:04.172)
Mm-hmm. Hijinks, yep. So the Mars costume, as you were talking about. I call it a suit. As we said, it&#39;s not a suit. It&#39;s a pair of, it&#39;s a pair, jeans could be a suit in Canada. Yeah, so it&#39;s a pair of like rubber feet, a pair of rubber hands, and it&#39;s a mask.</p>
<p>Dave! (23:15.106)
Yeah, it&#39;s like calling jeans a suit.</p>
<p>Dave! (23:20.593)
Oh, God.</p>
<p>Dave! (23:27.278)
This is some real party city shit and it really plays well.</p>
<p>Bryan! (23:29.356)
It, yeah, it&#39;s very, it really is like a spirit Halloween costume. It gives Mars a really weird look. It&#39;s like he&#39;s wearing a big puffy coat over his entire body. And sometimes like, you don&#39;t, they don&#39;t really give you a good look at him because I think that they&#39;re very conscious about the way that the full makeup appliance looks. But he kind of looks like a gray plush doll with whips, wispy hair.</p>
<p>and it gives the impression of being a sort of cuddly slasher killer.</p>
<p>Dave! (24:01.15)
He&#39;s like a he&#39;s like a reject Muppet. It&#39;s got like a big, big frizzy fright wig. It&#39;s kind of his face is a little bit mangled, but it&#39;s also kind of blue gray in a way that&#39;s, you know, you&#39;re like, OK, you&#39;re kind of adorable in a way.</p>
<p>Bryan! (24:04.662)
Yeah!</p>
<p>Bryan! (24:15.306)
Yeah. Yeah, like he&#39;s like an early like a test version of Oscar the grouch that they threw out.</p>
<p>Dave! (24:19.498)
Which I will say, you know, this movie is in a lot of ways really terrible, but there are a lot of things that they do kind of well, probably unintentionally, but like one of them is this, they&#39;re clearly trying to create a franchise character because this is not just a killer, this is a character.</p>
<p>Bryan! (24:37.033)
Right!</p>
<p>Bryan! (24:40.572)
Yeah, which is interesting because Friday the 13th hadn&#39;t even done that yet. Friday the 13th was supposed to be a one-off thing. And the end of the movie, yeah, like they get, and the killer, yeah, and then they give you that.</p>
<p>Dave! (24:49.138)
Yeah, you don&#39;t even see the killer until the end.</p>
<p>And it&#39;s just an old lady. Oh, sorry, spoiler alert, everybody. If you haven&#39;t seen Friday the 13th, you&#39;re an idiot.</p>
<p>Bryan! (25:01.878)
I know get the fuck out of here. What is happening? Who&#39;s even listening to this? Yeah, so like they actually have foresight but unlike most of the movies made at this point Like it was just one and done for some for whatever reason</p>
<p>Dave! (25:16.106)
Also, if anyone&#39;s listening and you&#39;re like, hey, I&#39;ve never seen The Burning, well, you don&#39;t need to. It was made by terrible people and it&#39;s also a really shitty movie.</p>
<p>Bryan! (25:25.504)
It&#39;s been a long time since I&#39;ve seen it. I can&#39;t really remember it very well.</p>
<p>Dave! (25:29.414)
Uh, one word. Weinstein. Sorry, Weinsteins. You get both of them. That&#39;s right. You thought you only wanted one? Well, you&#39;re getting both. And I could make a lot of really awful jokes from there, and I won&#39;t. And that&#39;s called growth.</p>
<p>Bryan! (25:32.261)
Ooh, that&#39;s right. Yeah. Yep.</p>
<p>Bryan! (25:42.71)
get you get you with both barrels on that one.</p>
<p>Bryan! (25:48.073)
Hahaha</p>
<p>Yeah, yeah. So the movie opens on a title card, and it says, &quot;&#39;It all started during a campfire at North Sea cottages, &quot;&#39;a special retreat for gifted children.&#39;&quot;</p>
<p>Dave! (26:03.166)
Alright, I gotta stop you right there. Why are there only six gifted children?</p>
<p>Bryan! (26:07.688)
That&#39;s the thing. OK, so let&#39;s get into it a little bit.</p>
<p>Dave! (26:10.594)
There are more counselors at this camp than there are children.</p>
<p>Bryan! (26:14.568)
This is, that is the weirdest quality of the movie. I&#39;ve got notes on it. So we get this like little March, like procession song that plays over the credits.</p>
<p>Dave! (26:30.386)
Are you talking about TP&#39;s like real hot Dust Bowl era story song that he entertains the children with?</p>
<p>Bryan! (26:35.957)
No, because that&#39;s what comes next. And it&#39;s him singing a song about a killer who kills people and drags their bodies off to the tune of the song you just heard.</p>
<p>Dave! (26:49.373)
Yeah, yeah. And, and while they&#39;re showing you clips from the film that you have not yet seen.</p>
<p>Bryan! (26:52.984)
of them dying! It&#39;s that&#39;s the oh my god, I want to know what the editorial decision about that was because it really</p>
<p>Dave! (27:02.142)
It defies every convention of storytelling that there is, anywhere.</p>
<p>Bryan! (27:06.704)
Yeah, so listener, here&#39;s what&#39;s happening. So there&#39;s a campfire, there&#39;s a bunch of people around it. You&#39;ve seen movies and there&#39;s one guy and this is like, I&#39;ve been to camp, this has never happened. Nobody has ever sung me a song.</p>
<p>Dave! (27:16.371)
You&#39;ve been outside before!</p>
<p>Dave! (27:25.246)
And I mean, this is like a talking blues song. Like there is an atonal, an atonal, arrhythmic dirge.</p>
<p>Bryan! (27:28.663)
It&#39;s a murder ballad.</p>
<p>Bryan! (27:35.096)
It&#39;s, yeah, yeah. And it&#39;s not about Mars. It&#39;s just about a guy who kills. Yep, but as he does this, he kind of like is like making his way around the circle and he stops at like this person and then he sings. And then as he sings to this person, it cuts to that person being murdered and then like dragged away. And then he goes to another couple of people and then you see them die.</p>
<p>Dave! (27:41.042)
It&#39;s gonna cut your head off and drag off your body.</p>
<p>Bryan! (28:03.28)
and then he cuts to another person and then you see them die, which is like, give us a little suspense here because now.</p>
<p>Dave! (28:09.078)
And in the meantime, he is getting on them. Like he&#39;s got, he comes up between two of the counselors, he&#39;s like got him by the neck and he&#39;s like rubbing their neck. Real sexy time. Real sexy time at TP&#39;s campfire.</p>
<p>Bryan! (28:16.128)
Oh yeah, I mean like get the fuck away from me TP!</p>
<p>Bryan! (28:22.256)
Yeah, yeah, just it&#39;s so weird. So he finishes his song and then Max, who I said either owns the campers like head counselor or something, he starts to tell us he tells the story of Madman Mars, but not before it.</p>
<p>Dave! (28:37.238)
But this is some real yes ending here because T.P. tells a well sings a story about, you know, killing and dragging off the bodies. And then Max tells a story that is essentially the story that T.P. just sang to them. And it is also the story that is about to unfold before us. This is a mindfuck of storytelling.</p>
<p>Bryan! (28:53.425)
Yeah.</p>
<p>Bryan! (28:58.789)
Right, but not before-</p>
<p>Bryan! (29:03.881)
Yeah, it&#39;s really, really weird. It&#39;s very experimental. But before he gets to it, he stops and he sort of gives the speech. There&#39;s a lot of people giving speeches in this movie.</p>
<p>Dave! (29:14.83)
Oh yeah, a lot of declarations in this movie. Lots of well-meaning declarations.</p>
<p>Bryan! (29:17.188)
Yeah, and this is a sort of a declaration, a sort of bit of exposition, if you the viewer watched any amount of the movie and wondered why it&#39;s not like summer camp. That&#39;s because this is a special gifted children&#39;s retreat that takes place at Thanksgiving.</p>
<p>Dave! (29:38.478)
It&#39;s a Thanksgiving camp. Now I will tell you why it&#39;s a Thanksgiving camp. I kind of wanted it to just be I was like, should I actually say it? And I thought, well, it&#39;s a fact. And there are so few of them about this movie, someone to share it anyway. I prefer to just think they just wanted to have a gifted Thanksgiving camp because why the fuck not? But it&#39;s because they had to wait so long to get their funding for this movie that by the time they got it, it was like October.</p>
<p>Bryan! (29:40.008)
Yep, this is Thanksgiving camp. Yeah.</p>
<p>Bryan! (29:56.756)
Ha ha ha!</p>
<p>Bryan! (30:05.552)
It was, the movie started production in November, so.</p>
<p>Dave! (30:07.806)
And so they had no choice but to throw in some line to explain why they it is the middle of summer, supposedly why they&#39;re all bundled up. And like you can see their breath. But apparently they had to like because they&#39;re outside the whole time. The set decorator had to like go around and glue green leaves to the trees. And if there was anything that looks too brown or dead, they would just spray paint it green. But it&#39;s like you already said it&#39;s Thanksgiving camp.</p>
<p>Bryan! (30:18.594)
Yeah</p>
<p>Bryan! (30:37.035)
That&#39;s the thing. Ah yeah.</p>
<p>Dave! (30:38.018)
They&#39;re just covering all their bases, this is my guess. But I like the fact that they included it because you could have said nothing and no one really would have noticed, but the fact that you&#39;ve said something now makes me think, what the fuck is Thanksgiving camp? Why are there only six children here? When does it take place exactly? Is it school? Is it school break? What&#39;s happening?</p>
<p>Bryan! (30:49.536)
would never have cr-</p>
<p>Bryan! (31:04.396)
Uh, uh, like, that is a very, very valid point, because, like, honestly, if they never said anything about it, I would not have questioned it. I&#39;m just not paying that much attention to this movie. But, uh, yeah, so now Max, so he starts to tell the story of, um, a madman Mars, an evil farmer who murdered his family and then was lynched by the town for it.</p>
<p>axes them all to death and then he runs to the town tavern and throws the axe on the bar and like orders a drink and instead of a drink he gets lynched.</p>
<p>Dave! (31:43.71)
Now, I will interject here because I had an interesting thought while I&#39;m listening to this story, because I was just like, why does he kill his family? I think he just kills his family because he&#39;s a piece of shit. But I think it&#39;s interesting because it&#39;s like he kills his family and then he goes to the bar and puts the axe down and is like, hey, everybody, I just killed my family. And you might think you might think, well, who would do that? And I would say Ronald DeFeo did that in upstate New York in the late 70s. Weird.</p>
<p>Bryan! (32:00.708)
You know what that sounds like?</p>
<p>Bryan! (32:08.876)
Yeah, yeah, that, yep, that one, that one dawned on me as well. So, uh, yeah, and so the story and the whole thing is, is like, you know, it&#39;s very, it&#39;s very spooky and it&#39;s, he doesn&#39;t name him until the very end. And, uh, he says like, he can&#39;t, he, we&#39;re not supposed to speak his name above a whisper. And then, uh,</p>
<p>Dave! (32:15.038)
Anyway, go on.</p>
<p>Dave! (32:29.65)
And he&#39;s like, his name is Madman Mars. And someone&#39;s like, I&#39;m sorry, what did you say his name was? He was like, oh, I said it&#39;s Madman Mars. You know, for everybody in the back, Madman Mars. Jesus Christ, if anyone is to blame for this, it&#39;s Vincent Price.</p>
<p>Bryan! (32:32.893)
Yeah.</p>
<p>Bryan! (32:37.172)
I said, Madman Mars.</p>
<p>Bryan! (32:42.599)
Yep, they say it a bunch of times.</p>
<p>Bryan! (32:48.5)
He would have brought so much craft. So counselor or maybe I don&#39;t think he&#39;s a counselor. I think he&#39;s supposed to be one of the kids. Richie. Motherfucking Richie. He is respect.</p>
<p>Dave! (32:56.514)
Oh, Richie. Son of a bitch.</p>
<p>And he&#39;s just like, what do you mean there were no bodies? Bodies just don&#39;t disappear. And I say, Richie, I make a living writing true crime and I will tell you this, bodies disappear all the time.</p>
<p>Bryan! (33:11.904)
Yep. This is, he is, I lay responsibility for everything that&#39;s about to happen on his shoulders. Starts talking shit about Mars, yells out his name, which you&#39;re not supposed to do, like we said. And then he, I suppose, but like he really, he really, he really puts some stank on it. And not only that, he throws a rock which smashes through a window into the house that&#39;s right nearby. It&#39;s supposed to be Mars&#39;s house.</p>
<p>Dave! (33:22.978)
Well, I mean, someone already did it, so, you know. I&#39;m gonna put that one on him.</p>
<p>Dave! (33:36.394)
And this is all starting to feel a little Texas chainsaw where you&#39;re like, wow, Leatherface is a real brutal killer. And it&#39;s like, okay, although these kids did just show up and walk right into his house, he clearly doesn&#39;t know what&#39;s going on. What are you gonna do? I mean, Richie just threw a rock through your window and then enters your house and is screaming at you.</p>
<p>Bryan! (33:51.772)
Yeah, yeah, castle, castle doctrine.</p>
<p>Bryan! (34:00.112)
I know if I were if that were to happen to me what I would do is go to the nearby camp and fucking murder everybody Yeah</p>
<p>Dave! (34:06.486)
This is some real home alone bullshit. Like, what are you gonna do? I mean, all, I would argue he is possibly just standing his ground.</p>
<p>Bryan! (34:15.612)
Yeah. And so the crowd, we&#39;re finally breaking up for the night, and they get all the kids are getting kind of grouped up to get taken back. And TP and Betsy, who is the character that Galen Ross plays, have a confrontation. And he wants to get with her, but she&#39;s all pissed off that he scared the kids, which makes me wonder, were they scared?</p>
<p>Dave! (34:45.182)
Well, see, that&#39;s my question, because she&#39;s like, got the of the six children that are here. I would say three quarters of them look like teenagers. She&#39;s got the one girl who looks a little bit like, oh, I don&#39;t know. And then she kisses her on the forehead and I&#39;m like, all right. Well, she&#39;s not your child, so maybe don&#39;t do that. But then she&#39;s just like, Betsy, are we going to get our heads cut off? And that&#39;s just like, no, of course, they&#39;re not going to get your well.</p>
<p>Bryan! (34:54.717)
Mm-hmm.</p>
<p>Bryan! (35:05.574)
Yeah.</p>
<p>Bryan! (35:11.951)
haha</p>
<p>Dave! (35:14.83)
Oh, you don&#39;t speak too soon, Betsy. But other than that, she doesn&#39;t seem that upset. I will say, however, I like that Betsy is like, no man, fuck you. Like you just told this dumb story, now these kids are upset. Also, you&#39;re kind of a jerk and a weirdo.</p>
<p>Bryan! (35:32.528)
Yeah, yeah, because that&#39;s really the substance of their argument is that he&#39;s possessive and he knows that once this camp thing is over, their relationship is over, which also kind of begs the question, how long did this camp go on for? Because a lot of these movies, when you watch these summer camp,</p>
<p>Dave! (35:50.538)
Well, it&#39;s November, so they&#39;re either in school or this is some weird prison camp.</p>
<p>Bryan! (35:57.628)
Yeah, because a lot of these summer camp movies often give you the impression that camp lasts all summer. All fucking summer. When in reality, they usually last like a week.</p>
<p>Dave! (36:08.842)
Yeah, I don&#39;t have children and when I was one, I was a pretty weird one, so I didn&#39;t engage in any of this stuff. I&#39;m not sure if this is how it goes. I don&#39;t think it is. I hope it&#39;s not.</p>
<p>Bryan! (36:14.602)
haha</p>
<p>Bryan! (36:19.64)
Yeah, yeah, but like the whole thing with their relationship is he&#39;s very clingy, he doesn&#39;t want it to end, but they&#39;re all from the city. And when they go back to the city, they&#39;re all gonna like break up or something like that. What&#39;s like...</p>
<p>Dave! (36:31.306)
Yeah, because he&#39;s like a big he&#39;s like a big baby. And he&#39;s just like, well, you&#39;re never going to want to see me again. It&#39;s like, dude, shut the fuck up. And and then thankfully, Stacey, one of the other ones comes over, the other counselors comes over and she&#39;s just like, hey, guys, shut the fuck up. You&#39;re in front of children and you&#39;re in public. We don&#39;t give a shit. Stop it. And I kind of love these like these moments where like Betsy is just like, no, fuck off, man. Like this is I&#39;m tired.</p>
<p>Bryan! (36:36.176)
Yeah. Yeah, he is very needy.</p>
<p>Bryan! (36:46.076)
You&#39;re right.</p>
<p>Bryan! (36:50.632)
Yeah, she&#39;s like, guys, you&#39;re making it weird.</p>
<p>Dave! (36:59.894)
Like I like these kind of moments of reality where she&#39;s like, Oh, this is some weird shit. None of us care. Take it somewhere else, kids.</p>
<p>Bryan! (36:59.933)
Yeah.</p>
<p>Bryan! (37:05.864)
Yeah.</p>
<p>So with the fire out, the boys line up to head back, but Richie sees a person standing in the trees watching everybody. And so he ends up hanging back as the rest of the boys sort of like march double, yep, double time. And he starts snooping around.</p>
<p>Dave! (37:21.243)
March off without moving their arms.</p>
<p>Dave! (37:28.842)
And then, but this is when this is also when TP smacks him in the back of the head. And it&#39;s like, why is Thanksgiving camp so physical?</p>
<p>Bryan! (37:36.68)
I know. He does a thing later on where he&#39;s like, Hey, you seen Richie the wise guy as he&#39;s like twi-</p>
<p>Dave! (37:42.338)
Yeah, and he&#39;s like got the kid&#39;s hands and I was like, why is he holding his hand? And I was like, oh no, he&#39;s twisting his hand, which again, what the fuck TP? You&#39;re a weirdo.</p>
<p>Bryan! (37:46.516)
He&#39;s twisting it.</p>
<p>It seems like he was given it seems like he was given like a like some direction that&#39;s like, like really get in there, twist the kid&#39;s arm. And he was like, Yeah, I don&#39;t, I don&#39;t know if I want to do that. So we kind of like halfheartedly heart heartily does it. I know it would it would have been</p>
<p>Dave! (38:00.982)
But that just makes it look creepy! It looks like he&#39;s caressing this teenage boy&#39;s hand.</p>
<p>Bryan! (38:08.212)
It would have been a thousand times less weird if he just like fucking yeah</p>
<p>Dave! (38:12.342)
Yeah. God damn it. Again, if you just didn&#39;t say anything, we wouldn&#39;t notice this shit.</p>
<p>Bryan! (38:18.789)
They gotta make it weird. Yep, so Richie heads to the dilapidated house behind the campfire spot and supposedly the home or madman Mars and he&#39;s where he starts just kind of snooping around and it&#39;s just like a, you know, abandoned house out in the woods.</p>
<p>Dave! (38:20.648)
Fly into the radar.</p>
<p>Dave! (38:34.982)
It is one of many, like, kind of real Scooby-Doo moments.</p>
<p>Bryan! (38:38.812)
Yeah, yeah. So back at camp, Betsy and Stacey have a conversation about T.P. being put, what does T.P. stand for, do you think?</p>
<p>Dave! (38:47.786)
Nobody says. They say they don&#39;t even know they never knew.</p>
<p>Bryan! (38:51.672)
Yeah, but apparently he&#39;s being possessive and not wanting to see it again. It&#39;s it&#39;s.</p>
<p>Dave! (38:56.258)
She&#39;s like, I like him, but he&#39;s also possessive and kind of weird. And it&#39;s like, OK, listen, if you like somebody, but also they&#39;re possessive and weird, I think that&#39;s a hard no. Look, ladies, you got to pass on this shit. There are better people out there.</p>
<p>Bryan! (39:06.352)
Well, here&#39;s the thing. She&#39;s. If she&#39;s if she&#39;s not into him emotionally, she likes, she&#39;s just into him for his body.</p>
<p>Dave! (39:15.731)
Well, and we&#39;re gonna see that body later, and I&#39;ll tell you right now. I&#39;m no Galen Ross, but I don&#39;t think so.</p>
<p>Bryan! (39:24.181)
Oh yeah, there&#39;s something about his face that weirds me out. He&#39;s got a very strange smile.</p>
<p>Dave! (39:28.202)
Yeah, there&#39;s- there&#39;s just everything. You look at TP and you think, God, fuck you, man. You suck. And he doesn&#39;t have to say anything either. It&#39;s like, I just... Ugh. Yeah.</p>
<p>Bryan! (39:37.123)
He&#39;s smarmy.</p>
<p>Yep. So outside TP and Max attempt to pull Chekov&#39;s axe out of a... there it is. Yep. Out of a stump in a weird reference to the Sword and the Stone. It&#39;s such a bizarre Chekov&#39;s axe moment.</p>
<p>Dave! (39:47.129)
There it is! That&#39;s note number 23.</p>
<p>Dave! (39:57.994)
Yeah, because there&#39;s like a legend, like if you can pull you over or it&#39;s a wager, if you can pull this fucking Chekhov&#39;s axe out of this stump, you get $100. Look, what kind of fucking weird legend wager is that? But also Max speaks in all these weird aphorisms and like. I&#39;m like, wow, you are the folksiest man ever.</p>
<p>Bryan! (40:10.717)
Yeah.</p>
<p>That&#39;s the thing. He, so he, so yeah, so TP is like, like really trying to pull this thing out and he kind of comes over and he like helps him out and he puts his hand on his shoulder and he gives him this whole like, he who stares into the abyss, best not stare long or stares back like it&#39;s like, be a good person.</p>
<p>Dave! (40:27.202)
You see? Yeah.</p>
<p>Dave! (40:31.85)
It&#39;s very like, TP, I&#39;m not your dad, but if I was, TP, if I was your dad, I would just smack you in the dumb face of yours. Everybody in this movie, it feels like they should all have googly eyes and you have to tell them not to stare into the sun. There is something wrong with every single person.</p>
<p>Bryan! (40:47.88)
hahahaha</p>
<p>Bryan! (40:52.576)
There, I&#39;m telling you, this movie is so fucking strange because...</p>
<p>Dave! (40:56.938)
Now, I watched all these fucking bonus features, and they&#39;re like, oh, Joe, the director, they&#39;re like, oh, he was so focused, he was so intense, he knew exactly what he wanted. And I&#39;m like, tell me what he wanted, because it is not apparent in this film. It feels like nobody directed it.</p>
<p>Bryan! (41:11.533)
Because the first, this is a movie that really kind of commits a few real horror movie blunders like right out of the gate where it doesn&#39;t give you a little taste of the action like a lot of these do. Nobody fucking dies until about 40 minutes into the movie. You don&#39;t even see Mars.</p>
<p>Dave! (41:21.826)
Starting with a story song.</p>
<p>Dave! (41:34.538)
Well, someone is about to die in the next second, but we don&#39;t give a shit because we don&#39;t know who any of these people are.</p>
<p>Bryan! (41:40.956)
Yeah, because there&#39;s a lot of character building moments that just are ultimately pointless because all of these people are just knife bait or ax bait in this movie&#39;s case. So like this.</p>
<p>Dave! (41:50.87)
Like, listen, y&#39;all, do you want to know why, why when Linda and Annie die in Halloween, why it&#39;s so sad and upsetting? Because we like them and because we know who they are. Now, you&#39;re all the alternative to that is I have to then hate them if you&#39;re going to just kill them off without any context. You can&#39;t give me neither of those and just be like, here are just some people. They&#39;re really, really weird and awkward. Don&#39;t get too attached, though. It&#39;s like, well, don&#39;t worry, I won&#39;t because I don&#39;t know anything about them.</p>
<p>Bryan! (42:00.521)
Yeah.</p>
<p>Bryan! (42:13.16)
That&#39;s the thing.</p>
<p>Dave! (42:19.334)
Other than the fact that they all seem a little bit confused.</p>
<p>Bryan! (42:22.504)
They&#39;re all, the thing is this is not, this is like not the opposite of Halloween where like every, like sleep away camp is like that where everybody except Angela and Ricky are ferocious maniacs who just deserve everything they get. These people in this movie, they&#39;re all reasonable and pleasant and like thoughtful.</p>
<p>Dave! (42:46.902)
Like to a fault, to a very weird fault. Where you&#39;re just like, do any of you know where you are right now? And I don&#39;t think they do.</p>
<p>Bryan! (42:51.611)
Yeah.</p>
<p>Bryan! (42:57.662)
know. Yep. So speaking of Dippy, yeah, we meet D- no, we meet Dippy, who is the Camp Silent Cloud Cook, maybe. He&#39;s- he&#39;s- he&#39;s a-</p>
<p>Dave! (42:59.879)
Oh, sweet dippy. Not long for this world, really.</p>
<p>Dave! (43:08.502)
I don&#39;t, what is, what is, what is the point of Dippy? Because he just shows up and he&#39;s the drunk chef. Now, I don&#39;t know why you need a chef for six children, but he&#39;s drunk and he&#39;s like blowing into the bottle and then Stacy comes along and she&#39;s like, Dippy, I think it&#39;s empty. And he&#39;s just like, hmm, yeah.</p>
<p>Bryan! (43:14.801)
and then he gets killed.</p>
<p>Bryan! (43:30.296)
doesn&#39;t speak a word, he just gives her like the stink eye, that he gets up and he wanders into the kitchen or whatever, and he opens up like a cabinet and there&#39;s.</p>
<p>Dave! (43:40.194)
Cause he&#39;s going to get another bottle of something. Now, another question. Why do you have a pantry full of liquor at Thanksgiving gifted camp?</p>
<p>And we&#39;re gonna come back to this question a bunch of times, and you&#39;re just gonna swap out liquor cabinet with any other thing I&#39;m about to say.</p>
<p>Bryan! (43:52.992)
She&#39;s a.</p>
<p>Bryan! (43:59.448)
Oh, I know, I know. But he gets, yeah, there&#39;s Mars and he kind of, he doesn&#39;t jump out, he just, it&#39;s like the scene in My Bloody Valentine where the guy opens up the locker, he gets hit with the pickaxe. It&#39;s like that. Yeah, that seems, no.</p>
<p>Dave! (44:13.014)
Yeah, except that scene&#39;s awesome. This scene doesn&#39;t make any fucking sense, and he&#39;s got his like, he&#39;s like a very long, weird nails, cause he cuts, he like slashes into his.</p>
<p>Bryan! (44:24.628)
Yeah, he rakes his throat and cuts him open. That&#39;s okay. So that brings up an important question about Mars because in the beginning of the movie, when we&#39;re introduced to him through song, he&#39;s just a normal person. But when we finally see him, he&#39;s a monster. Like...</p>
<p>Dave! (44:43.166)
Yeah, he&#39;s like person slash lion.</p>
<p>Bryan! (44:46.144)
He, yeah, and he doesn&#39;t, he never speaks. He, he does, he&#39;s like Karloff in the old dark house where he&#39;s just like, Grrr, grrr, grrr. There&#39;s even a scene.</p>
<p>Dave! (44:48.014)
It&#39;s like a Lurssen.</p>
<p>Dave! (44:54.838)
Yeah, but Karloff in the old dark house is like, you get that he&#39;s just a person. He&#39;s just a maniac. He&#39;s like a wild fiend. This guy is sort of like, he&#39;s sort of supernatural. He&#39;s kind of animalistic, but also he&#39;s a slasher. It&#39;s like, you can&#39;t have all of them.</p>
<p>Bryan! (44:59.955)
Yeah.</p>
<p>Yeah, it&#39;s just, it&#39;s-</p>
<p>Bryan! (45:12.644)
They never really lay the lore out, which is really kind of a problem because like even in the scene where we see him get lynched, he&#39;s like wearing boots and shit. And then when we see him now.</p>
<p>Dave! (45:21.182)
Which I go back to say, like, what did the original script look like? Because if you did all these changes, and I am willing to give them the benefit of the doubt that a lot of this is because they had to do all this like last minute retooling, but it&#39;s like, you couldn&#39;t have had that much to begin with because there&#39;s nothing here. There&#39;s no story here, which is probably why everyone is just like, oh, hey, man, let&#39;s hang out by the fire and play the recorder and not get.</p>
<p>Bryan! (45:42.918)
Yeah.</p>
<p>Bryan! (45:50.62)
Yeah, so yeah, it&#39;s just the nature of Mars is really kind of up in the air. He&#39;s a monster, or maybe he&#39;s a person. Maybe he&#39;s undead. Maybe it&#39;s never established. It&#39;s really fucking weird. He&#39;s got monster claws, and he runs around in big puffy feet. Yeah, so meanwhile, everybody finally realizes that Richie isn&#39;t there, because he&#39;s still at Mars&#39; house poking around.</p>
<p>Dave! (46:14.23)
And one of the kids goes, Hey, don&#39;t worry, man. Richie knows what he&#39;s doing. Look, look, gifted camp. I don&#39;t think Richie knows what he&#39;s doing. Richie, Richie is the future TP. He sucks.</p>
<p>Bryan! (46:18.952)
Yeah.</p>
<p>Bryan! (46:22.789)
No, Richie&#39;s.</p>
<p>Richie sucks so much. Fuck Richie.</p>
<p>But yeah, back at camp, Max gives another speech about how.</p>
<p>Dave! (46:35.658)
Oh, this scene. Oh, man. Well, they&#39;re all gathered around the table. This whole fucking thing feels like a weird, like Christian instructional video from the 80s. Like, these are the whitest people you&#39;ve ever seen anywhere.</p>
<p>Bryan! (46:38.304)
This scene&#39;s real wild.</p>
<p>Bryan! (46:43.285)
Hmm.</p>
<p>Bryan! (46:50.027)
Oh yeah, he gives him a speech about-</p>
<p>Bryan! (46:55.38)
Very much, yeah. He gives them a speech about how special the counselors all are, how the impression that they&#39;ve made on the lives of these youngsters will last a lifetime, and I&#39;m like, I don&#39;t, I don&#39;t fucking know.</p>
<p>Dave! (47:08.542)
are any of you talking about? This is insane.</p>
<p>Bryan! (47:12.403)
Yep. It&#39;s all, it&#39;s very touching and again very weird. So</p>
<p>Dave! (47:16.906)
And then Betsy says, like a contestant on Project Runway, I just need to say something. Excuse me, can I just say something? Like it&#39;s fucking Top Chef. She&#39;s just like, excuse me, Max. Max, I just need to say something, okay? And it is the weirdest, like, why are you doing this now at the end of camp? Just shut up.</p>
<p>Bryan! (47:25.224)
Yeah, it&#39;s like...</p>
<p>Bryan! (47:37.78)
Yeah, she airs, she airs her grievances about telling scary stories to the children to which Max is like, he puts his hand on her shoulder is like, thank you. And just so you know, I&#39;ll only tell it to the older campers next year. She&#39;s like, thank you. It&#39;s like fucking group therapy.</p>
<p>Dave! (47:57.098)
It&#39;s like everybody is just like, you know what? You&#39;re right. No, no, I&#39;m not. You&#39;re right. No, I can&#39;t be right. You&#39;re right. You know what? We&#39;re all right.</p>
<p>Bryan! (48:02.488)
Yeah, it&#39;s very I it&#39;s very I&#39;m okay, you&#39;re okay. Like it&#39;s so like period of Yeah, and then they applaud</p>
<p>Dave! (48:09.066)
It&#39;s so fucking weird. Like, everyone is so agreeable in the weirdest way. And then and then T.P. has to make his because then because that&#39;s when Max is like, well, I know that there&#39;s some beers hidden away, kids. Just save one for me. Ha ha. Ring a ding and out the fucking door. And then.</p>
<p>Bryan! (48:17.276)
Yeah, they all applaud.</p>
<p>Bryan! (48:31.812)
Yeah. Oh, but where&#39;s he going?</p>
<p>Dave! (48:36.498)
Oh, he&#39;s got to go play cards. He&#39;s got to go into town and play cards. Like, this is all very convenient. He&#39;s got to pick up the shutters and then play cards. And then TP is like, now I would like to say something. And everybody, if it were me, I&#39;d be like, Jesus fucking Christ. But everybody&#39;s like, oh, you have something to say, too? Well, we&#39;re very respectful. So please go on, TP. And he says earlier tonight.</p>
<p>Bryan! (48:51.924)
This fucking guy. Yeah.</p>
<p>Dave! (49:04.254)
you witness an unfortunate scene between Betsy and I, and it&#39;s just like, what is happening? Why are you doing this? And he is just like, I was very selfish and unkind, and I&#39;m embarrassed that you had to see that. And I&#39;m especially sorry to Betsy, to whom I was a raging cunt face. And my words, not his. They don&#39;t say that in Christian instructional videos, but they should. And then he&#39;s like, now I would like to</p>
<p>Bryan! (49:11.123)
Yeah.</p>
<p>Bryan! (49:24.175)
Hahahaha</p>
<p>Dave! (49:32.958)
Raise a toast to friends and friendship, to love and lovers. May you always have more than you need. What the fuck?</p>
<p>Bryan! (49:37.6)
to love and love us.</p>
<p>Bryan! (49:43.504)
Yep. More applause. It&#39;s, that&#39;s the thing is this movie is like 60% filler and it all, and all the filler happens upfront. And it&#39;s this just bizarre stuff that like they must&#39;ve written on the day. Like this could not possibly have been part of a script. They must&#39;ve been like, okay, like we&#39;re about like five minutes short of a feature. Cause this movie isn&#39;t even 90 minutes. And so they&#39;re like, okay, max.</p>
<p>Do the whole like wise old man thing and then TP, like, I don&#39;t know, just like chew the scene a little bit. Whatever it is you&#39;re doing, yeah.</p>
<p>Dave! (50:18.498)
Do your whatever that thing is. And it is so bizarre and they&#39;re all just like, you know what? Here, here to friendship. God, TP, you&#39;re just the best. No, Betsy, you&#39;re the best. No, Stacy, you are the best. God, I love you all. And they all just fall into a fucking pile together. So, so weird. It&#39;s just yes. It&#39;s just two lovers. On a vacation, two lovers.</p>
<p>Bryan! (50:29.685)
Hehehe</p>
<p>Yeah. Two... Two lovers! Yeah.</p>
<p>Bryan! (50:43.09)
Yeah. Two lovers. So Max takes his leave and we cut to the worst sex scene ever committed to film.</p>
<p>Dave! (50:50.57)
No. This is one of the greatest things ever coming into film. It is long and awkward as hell.</p>
<p>Bryan! (50:54.032)
I... I crawl inside myself every time I see this scene. So...</p>
<p>Dave! (51:04.186)
Starting with the music, because the music, I believe that is T.P. singing. I think it is, I&#39;m pretty sure it is. And this song is just like, for loving lovers who wanna be in love together, lovers getting into a hot tub. One of the lines is, a magician does his magic, fills you with a slick trick. What?</p>
<p>Bryan! (51:05.588)
The music.</p>
<p>Bryan! (51:09.293)
No!</p>
<p>Bryan! (51:18.892)
Hahaha</p>
<p>Bryan! (51:29.056)
AHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH</p>
<p>Dave! (51:33.526)
Damn it!</p>
<p>Bryan! (51:34.248)
Nah, that&#39;s the fucking worst. It&#39;s, it&#39;s so, so these, like I, I hate it when they, when they feel like they gotta put a fucking sex scene in it, just to get the, just to get some, some skin in the scene.</p>
<p>Dave! (51:38.462)
Yeah!</p>
<p>Dave! (51:47.33)
So here&#39;s the thing. Here&#39;s the thing about this sex scene and why it&#39;s so ridiculous. Now, again, this is me ruining this incredible moment. Apparently, this whole thing takes place in a hot tub like you have at a Thanksgiving gifted camp.</p>
<p>Bryan! (52:02.828)
A gigantic, gigantic hot tub.</p>
<p>Dave! (52:05.65)
Yeah. And so the whole thing takes place in a hot tub. Apparently, while they&#39;re filming it, Galen Ross is like, okay, this is kind of awkward. So she&#39;s like, gives T.P. a little bit of direction about like, maybe we could like, it would look a little more realistic if we did it this way. Apparently, apparently he didn&#39;t like her recommendation and stormed off and like locked himself in the sauna and would not come out.</p>
<p>Bryan! (52:21.969)
If we did it that way.</p>
<p>Bryan! (52:33.724)
Ugh.</p>
<p>Dave! (52:34.282)
And I don&#39;t know if it&#39;s just like, well, she&#39;s a bitch. It&#39;s like, well, okay, but she&#39;s awesome. Yeah, this feels like I look at him and I&#39;m like, you seem like the kind of guy that would do this. And so the only way they could get him to come out, because he&#39;s like, he was basically like, I&#39;m not gonna take no direction from a lady or whatever. And they were like, all right, fine. After like hours. And they were like, all right, if we play it like a comedy scene and we just kind of blow, like we gotta get this fucking thing done.</p>
<p>Bryan! (52:39.008)
This is very on brand for TP.</p>
<p>Dave! (53:04.142)
Can we just get through it? And so he agreed to come out if they did it in like kind of a goofy way, which is why it feels kind of goofy. But like it is the least sexy thing I can imagine. Like you get like them getting into the hot tub and it&#39;s like, like you see him undressing and he&#39;s got his teepee belt buckle.</p>
<p>Bryan! (53:09.533)
Yeah.</p>
<p>Bryan! (53:16.752)
It is the polar opposite of sex.</p>
<p>Bryan! (53:26.208)
He&#39;s got a big TP belt buck.</p>
<p>Dave! (53:28.822)
which I would argue is a Chekhov&#39;s belt buckle. But the weird thing is, is like, you see them undressing, you mostly see T.P. undressing, and it&#39;s not sexy. And then you see his like, you see his ass as he gets into the thing and it&#39;s like, what is this? What&#39;s happening here? Whose movie is this? Who is this for? Who wants to see T.P.&#39;s ass?</p>
<p>Bryan! (53:33.712)
I can see it.</p>
<p>Bryan! (53:40.094)
Yeah.</p>
<p>Bryan! (53:45.127)
It&#39;s...</p>
<p>Bryan! (53:52.764)
Yeah, yeah. Well, they&#39;re gonna give equal time because there&#39;s a, you get Galarostopolis for like a split second, but we get a long, 30 second descent of TP&#39;s ass into the hot tub.</p>
<p>Dave! (53:58.535)
I guess.</p>
<p>Dave! (54:08.71)
And then the two lovers just swirl about one another in the hot tub. I don&#39;t even know. I don&#39;t even know how to adequately describe this because they are literally just sort of like imagine you&#39;re just like push your body up against a wall and then you kind of roll your way along the wall without really pulling away from it. It&#39;s like that, except they&#39;re rolling along the inside, just opposite one another. And she was like, I think you can tell.</p>
<p>Bryan! (54:13.922)
Circulating you like sharks.</p>
<p>Dave! (54:38.482)
in this scene that we&#39;re not too happy with each other because we&#39;re not getting any closer than we absolutely have to, which is why we&#39;re both backed up to the corners of the thing. And it&#39;s like, okay, well, I guess that makes sense then. As you clumsily spin your way around as though it&#39;s sexy or something. And then there&#39;s, and then like...</p>
<p>Bryan! (54:48.224)
Yeah.</p>
<p>Yeah.</p>
<p>Bryan! (54:57.32)
Yeah, because it&#39;s like, it&#39;s, there&#39;s, it&#39;s sultry too. And the music really sets the mood, which is just absurd. Front to back, it&#39;s so awkward and awful and weird. And I, I hate it. I fucking hate it.</p>
<p>Dave! (55:15.446)
Because it&#39;s not like they&#39;re even, you know, and obviously, aesthetics are an objective objective, subjective, subjective thing. And but it&#39;s not like they&#39;re classic beauties, either one. So you&#39;re taking these two kind of like rustic looking people and putting them in this awkward scene that you drag out for a really long time.</p>
<p>Bryan! (55:31.354)
Yeah.</p>
<p>Dave! (55:44.178)
and put some weird-ass music behind it. I don&#39;t know how I&#39;m supposed to feel, but sexy is not it. But you know who does feel sexy about it? Madman Mars.</p>
<p>Bryan! (55:44.608)
So long.</p>
<p>Bryan! (55:51.028)
No, no. But Mars, mad man Mars is watching. And he looks in at the two of them through a window and we find out that TP is maybe not a terribly generous lover. Yep.</p>
<p>Dave! (56:03.582)
No. Nope. Because she comes up in the middle and says, I was right in the middle. You didn&#39;t let me finish. And my note just says, in the middle of what?</p>
<p>Bryan! (56:09.804)
is this something like, like you didn&#39;t, you didn&#39;t let me finish or something?</p>
<p>Dave! (56:25.83)
And I&#39;m pretty sure... I am a middle-aged man, I know what they&#39;re in the middle of. But that scene doesn&#39;t suggest that that&#39;s what&#39;s happening.</p>
<p>Bryan! (56:34.016)
Yep. But, uh, yep. So now fucking stupid fucking Richie is still out sneaking around in the forest. And apparently there are no trails in this campground. Like, there&#39;s the only way to move around the landscape is to like push your way through the brush and the bushes and shit. Because like even when they&#39;re at the campfire at the beginning, like when they leave, they have to like push aside foliage and shit. Like there&#39;s just no easy way.</p>
<p>to get to the camp. Like it just seems like everybody&#39;s out like wandering around through dense brush. So yeah, we cut back and for.</p>
<p>Dave! (57:12.875)
Not everybody. I mean, Stacey has made her way down to the shore where she is sitting in a boat on the shore playing the recorder.</p>
<p>Bryan! (57:17.084)
Yeah, she&#39;s, you know.</p>
<p>Bryan! (57:22.94)
Yep, which maybe. OK, so if maybe if the if TP got to sing love theme from Madman, maybe this was like another contingency of her contract where she was like, OK, I get to also show my talent for playing the recorder in the movie.</p>
<p>Dave! (57:25.871)
Why is she playing the recorder?</p>
<p>Dave! (57:33.218)
I&#39;m sorry.</p>
<p>Dave! (57:42.98)
I guess it&#39;s sort of like when you have like Sean Cassidy is in a TV movie and they&#39;re like, okay, but he also has to sing two songs. Maybe, but it&#39;s just like what? I guess like, okay, we want to give her something to do. Why is recorder where your brain goes?</p>
<p>Bryan! (57:47.847)
And he gets to... he also gets to do a run!</p>
<p>Bryan! (57:57.573)
Bye!</p>
<p>Yeah, like she&#39;s out in her happy place and it&#39;s we don&#39;t actually hear her play it the song the music that we hear it&#39;s almost like with Ralph Wiggum with a flute up his nose like it&#39;s like that. It&#39;s No, it&#39;s for fifth graders. Yeah. So on our way back to camp. She struggles to climb up a dirt embankment and narrowly escapes Mars but she&#39;s</p>
<p>Dave! (58:10.09)
Yeah, because that&#39;s all a recorder sounds like. There&#39;s no adult that plays this. It&#39;s for children in first grade.</p>
<p>Dave! (58:26.294)
But then she turns back, she gets to the top of the hill and turns back, and she kind of gives it this look like, yeah, fuck you. It&#39;s like, wait, how did you get down there in the first place? Nothing about this movie makes sense.</p>
<p>Bryan! (58:31.668)
Yeah.</p>
<p>Bryan! (58:35.828)
I know. So, and they do this, no, they do that, and they do that thing with the near miss with Madman Mars like a bunch of times. And I find it such a dubious trick because</p>
<p>Dave! (58:48.938)
Yeah, because you would know if someone is four feet behind you, especially when that someone is going,</p>
<p>Bryan! (58:52.724)
Yeah.</p>
<p>Bryan! (58:57.618)
Yeah, so now we cut back to the camp for another one of the movies weirdest fucking scenes.</p>
<p>Dave! (59:03.702)
This is my favorite part of this entire movie.</p>
<p>Bryan! (59:06.296)
It&#39;s okay. So it&#39;s set up like a group sex scene in a porno.</p>
<p>Dave! (59:12.466)
Because what, why are they all, they&#39;re all chilling by the fire. Well, there&#39;s four of them at this point, and they&#39;re like, you don&#39;t see them smoking weed, but I guess we&#39;re supposed to assume. But the problem is, they have seemed like they are stoned out of their fucking gourds for the entire movie. How am I supposed to know the difference?</p>
<p>Bryan! (59:19.312)
Yeah. Yeah, so it&#39;s.</p>
<p>Bryan! (59:25.217)
I think...</p>
<p>Bryan! (59:33.96)
Yeah, so that&#39;s why this is such a... This is why it&#39;s so weird. So they&#39;re like stacked side by side, like head to head. So there&#39;s like two facing up and two facing down. And they&#39;re all, but they&#39;re all talking. Like they, like either, like either they just, all four of them got like concussions simultaneously or...</p>
<p>Dave! (59:51.882)
Yeah, but they&#39;re like, one of them&#39;s like, I love fire. I love the way it destroys everything. And then Ellie is like, no, man, I don&#39;t like fire. This destruction is not that beautiful, man. And then it&#39;s that. It&#39;s that. It&#39;s this sort of like, how do I know that your reality is my reality? And how do I know that I&#39;m here and you&#39;re not there? And it&#39;s like, what is happening? You are in charge of children right now.</p>
<p>Bryan! (01:00:04.455)
Yeah.</p>
<p>Bryan! (01:00:10.407)
Yeah.</p>
<p>Bryan! (01:00:17.148)
Without the context of them like smoking a joint right there, it just acts like it&#39;s four people being really weird with one another. Cause this is the part where Dave gets up and he grabs a knife and he walks around. Yeah. And he walks around and his whole thing is like, it&#39;s like you never really know a person. Like I could be thinking about murdering all of you right now. It&#39;s like a, it&#39;s like a.</p>
<p>Dave! (01:00:29.374)
Like a goddamn psychopath!</p>
<p>Dave! (01:00:41.522)
Is he supposed to be a red herring? Or is this just like a feller moment?</p>
<p>Bryan! (01:00:46.769)
I think that it was, I think you&#39;re onto something with that because I also used to think that Max&#39;s disappearance was a part of the red hair. It was like a red herring also. But this whole scene when he does this thing with like, I could cut your heads off and drag your bodies away, like this whole thing, it seems like he&#39;s giving a monologue for like theater class or something. It&#39;s such a.</p>
<p>Dave! (01:01:11.554)
And it really feels like, because he is just fucking swinging for the rafters. And then they all laugh and they fall into a pile and they&#39;re definitely gonna fuck, but they don&#39;t. They don&#39;t. But the 80s, the 80s was the horniest decade. And I&#39;m not saying the 80s was like a hot, sexy decade. I&#39;m just saying it was a real horny decade. Like in that like sweaty, perms and corduroy kind of way.</p>
<p>Bryan! (01:01:16.412)
Yeah, yeah. And then he jumps on him.</p>
<p>Bryan! (01:01:25.094)
Nope.</p>
<p>Bryan! (01:01:34.948)
Yeah.</p>
<p>Bryan! (01:01:40.336)
Yeah, yeah, we went over that with House and Sorority Row, where it was like, this is still like that hangover from the 70s thing.</p>
<p>Dave! (01:01:48.118)
Yeah, it&#39;s just like everybody wants to fuck everybody all the time, even when they&#39;re screaming in your face with a knife in your hand about cutting up your bodies. And how do I know my reality is yours? It&#39;s like it&#39;s all in good sexy fun, everybody. Jesus Christ, if only we could be that way now. I mean, I don&#39;t really want to be that way now, but I just wish we had that same sort of sense of wonder, sexy wonder about the world.</p>
<p>Bryan! (01:02:01.187)
Yep.</p>
<p>Bryan! (01:02:05.599)
Yep.</p>
<p>Bryan! (01:02:11.588)
It does, it seems very, very freeing. Yeah. So back to stupid fucking Richie in the bushes again, still not dead, still not being killed for the horror he brought down on his group of weirdos. There&#39;s so much fucking filler in this movie. So.</p>
<p>Dave! (01:02:18.127)
Ugh.</p>
<p>Dave! (01:02:26.314)
Oh, this is when TP goes out to find him. He&#39;s like, and he&#39;s in the cab. This is when he twists the kid. He&#39;s like gently caressing the kid&#39;s hand, but also twisting it maybe. And he was like, I got to go find Richie. And he turns he&#39;s like, nobody leaves this cabinet, dude. There are two of them. What do you mean, nobody leaves the cab?</p>
<p>Bryan! (01:02:34.109)
Yeah.</p>
<p>Bryan! (01:02:44.656)
Yeah. So as he&#39;s leaving, Betsy sees the shadow of Mars in the camp as she sets off and commits Slasher Movie Blunder number one, which is always follow up when you think you saw something. But she does the usual slasher thing and says, never mind.</p>
<p>Dave! (01:03:01.934)
I&#39;m just a lady. You go ahead.</p>
<p>Bryan! (01:03:03.472)
Yeah, so we got a little POV of Mars stalking TP. Now, Ellie and Bill have struck out on their own. They&#39;re setting up a tent, declaring their look.</p>
<p>Dave! (01:03:13.142)
Let me tell you something. My love for Ellie in this film is deep and true. She is, she is bringing so much chilly in Friday, part three energy to this movie. That is so incredible.</p>
<p>Bryan! (01:03:18.221)
She&#39;s a very special lady.</p>
<p>Bryan! (01:03:31.912)
got such a weird vibe. Because she has this like very slow easy smile. And the way that she delivers all of her lines. She&#39;s got like head injury vibe about her. So they&#39;re out there declaring their love for one another. They decide</p>
<p>Dave! (01:03:38.602)
That one, she&#39;s been hit in the head.</p>
<p>Dave! (01:03:49.006)
Because they get the they decide this is after they all don&#39;t apparently don&#39;t fuck in the big cabin with everybody else. She and Dave go off and they&#39;re going to put up a tent and they&#39;re going to do it in the tent is my guess. And she climbs into the tent and he&#39;s in there and she looks up and says great moon tonight. And I thought, bitch, you are inside. You cannot see the moon. Why are you looking up?</p>
<p>Bryan! (01:04:13.844)
She says.</p>
<p>Yeah, so this is after this is that&#39;s right after that they decide that sex is better than going for a swim Which I gotta agree with them because it&#39;s fucking november</p>
<p>Dave! (01:04:25.814)
Yeah, because it&#39;s Upstate New York in November.</p>
<p>Bryan! (01:04:28.592)
And she looks, yeah, she looks wistfully at the sky and goes, great moon tonight, Billy, eh Billy boy?</p>
<p>Dave! (01:04:34.482)
Except she doesn&#39;t because she&#39;s looking wistfully at the top of the tent. Because she&#39;s inside. But she&#39;s she is high in the entire movie because she&#39;s just like, oh, man, wow. Oh, totally. We should get in. You don&#39;t want to go back to school. You just want to live in a tent. Oh, cool, man. Yeah.</p>
<p>Bryan! (01:04:40.589)
Maybe, maybe she&#39;s still high, who knows.</p>
<p>Bryan! (01:04:59.237)
Yeah. So TP is out in the woods being stalked by Mars. We get a flash and a killer real quick. It&#39;s when he&#39;s like using the flashlight, sort of look through the bushes and we see him real quick and then he comes back and he&#39;s gone. You show up.</p>
<p>Dave! (01:05:07.726)
Oh, and he&#39;s got the Party City Fright wig. It&#39;s some real Warner Brothers cartoon shit, where he just like scans the tree line and what? He goes back and there&#39;s nothing there. But then he&#39;s just like, Richie, is that you? And it&#39;s like, you think this giant purple-faced Party City Fright wig is a teenage boy?</p>
<p>Bryan! (01:05:14.18)
It&#39;s really, really goofy.</p>
<p>Bryan! (01:05:19.568)
Yeah, so.</p>
<p>Bryan! (01:05:30.656)
He, yeah, this is classic slasher movie bullshit where they go, they shout the name, hey, so-and-so, hey, I know it&#39;s you, come out. And then they, you know, they get stabbed, they get slashed, they get cut. You know, something like that happens. I fucking hate when that happens because it&#39;s just not what anybody would ever do. But it&#39;s 37 minutes in, the killing finally starts and TP gets a noose thrown over his neck and he&#39;s dragged through the woods before Mars, throws the rope over a tree branch and lifts him up.</p>
<p>Dave! (01:05:59.978)
And this scene, I will tell you, this is one of the moments where they get it right because this scene is really harrowing.</p>
<p>Bryan! (01:06:07.921)
I got a note in here about that where for such a soft movie up to this point, it&#39;s a pretty nasty kill scene.</p>
<p>Dave! (01:06:14.678)
Because the makeup is so realistic, like his face is all like kind of red and purple.</p>
<p>Bryan! (01:06:19.976)
purple around the eyes and his eyes roll up. But it&#39;s not just, and it doesn&#39;t really end there. It&#39;s quite long where like TP like at one point like manages to sort of like lift himself up to like relieve the pressure of it. He grabs the tree branch, but he&#39;s trying to lift himself so he can like get this thing off of his neck. And that&#39;s when Mars comes over and grabs him by the TP belt buckle and yanks him downward to break his neck. And then that&#39;s where he dies. It&#39;s pretty rough. Yeah. Like the kills in this movie are</p>
<p>Dave! (01:06:43.688)
And it&#39;s real rough.</p>
<p>Bryan! (01:06:48.636)
Not terrible. They&#39;re, most of them are pretty intense.</p>
<p>Dave! (01:06:52.658)
I think we have Dippy to thank for that. I think he did all the effects.</p>
<p>Bryan! (01:06:54.74)
Yeah, good old dippy. Yep. So we cut to camp again where grown ass woman is doing the he loves me he loves me nothing.</p>
<p>Dave! (01:07:06.102)
But in like a weird way, like she believes it. Cause every time she&#39;s like, oh, he loves me not. And it&#39;s like, bitch, you realize you&#39;re the one doing this, right? This is not magic. Yeah. Then in comes Stacy.</p>
<p>Bryan! (01:07:08.767)
Yeah.</p>
<p>Bryan! (01:07:14.74)
lady you&#39;re like you&#39;re like 30 years old what are you doing yeah little is yeah little does she know that uh tp&#39;s dead but you will never ever know so uh we cut back to camp yep that&#39;s that um she gets dave to go looking for richie and tp and</p>
<p>Dave! (01:07:24.234)
Yeah, it doesn&#39;t matter. It doesn&#39;t matter if he loves you or loves you not.</p>
<p>Dave! (01:07:36.438)
Well then, but then Stacy&#39;s just like, well, I&#39;m gonna go look too. She&#39;s like, wait, no, don&#39;t go. And Stacy&#39;s like, that&#39;s the difference between us. You&#39;re content to just stay here and I&#39;m gonna take action. It&#39;s like, you know, Stacy, for someone who plays the fucking recorder, you&#39;re kind of a bitch.</p>
<p>Bryan! (01:07:43.869)
Yeah.</p>
<p>Bryan! (01:07:53.724)
She&#39;s not a nice lady. But also, Betsy is like one of the most humorless people in the entire movie. Like...</p>
<p>Dave! (01:07:58.866)
She really is. And I&#39;ll tell you, having watched these interviews with Galen Ross, I think maybe Galen Ross did not have the best time on this movie. Because even everybody in the other interviews is like, oh, Galen, yeah, you know, I never had any problems with her. And it&#39;s like, I&#39;m sorry, did someone suggest there were problems with Galen Ross? Because that&#39;s a really weird way to start that sentence.</p>
<p>Bryan! (01:08:17.468)
I&#39;m out.</p>
<p>Bryan! (01:08:21.349)
Yeah, yeah, there is a.</p>
<p>Dave! (01:08:23.522)
Cause that&#39;s a real like, look, I&#39;m not racist, but like, wait, what? Okay.</p>
<p>Bryan! (01:08:29.921)
Yeah, she, she has, there&#39;s a quality to her character that is like, and you could see it on her face where it&#39;s just like, let&#39;s just get this over with. But it&#39;s time for Mars to pull Chekhov&#39;s axe out of the stump and become the rightful king of England, which he does. Now he goes out and stalks Dave in the forest. We have another one of those like near miss things where like,</p>
<p>Dave steps out of the way right before he gets hit by the axe or something like that. But he does bump in.</p>
<p>Dave! (01:09:01.006)
Again, it&#39;s that real kind of cartoony setup where it&#39;s just like he leans down to pick up a flower And just as he does it the axe swings above his head. It&#39;s like how would you not notice any of this happening?</p>
<p>Bryan! (01:09:08.952)
Yeah.</p>
<p>There&#39;s a lot of Scooby-Doo shit happening in this movie.</p>
<p>Dave! (01:09:15.062)
And I&#39;ll tell you, as an adult man who loves Scooby-Doo, I appreciate it.</p>
<p>Bryan! (01:09:20.929)
Yeah, but he bumps into TP&#39;s hanging body and now like a struggle ensues. And there&#39;s a bit of a buildup intention as he sort of like hides behind a fallen tree and he like pulls his knife out. And then, you know, but then eventually, you know, Mars is on him and there&#39;s like a lot of ax swings. And then finally he hits Dave and we cut back to the camp where Stacey excuses herself to go find the others.</p>
<p>Dave! (01:09:45.206)
Yeah, Madman Mars, he is a oddly agile person for someone dead and gigantic.</p>
<p>Bryan! (01:09:52.4)
Yeah, you actually see him leap in this scene, which is one of the...</p>
<p>Dave! (01:09:55.526)
And the guy, Paul Ellis, was... He is not a small person. And they were like, oh, well he does martial arts. And they show pictures of him, and it&#39;s like, well, he seems like the kind of guy who would say that he does martial arts, but he doesn&#39;t look like he does.</p>
<p>Bryan! (01:10:08.894)
Yeah. So, yeah. Stacey&#39;s plan is to go find Bill and Ellie, who are currently knocking boots in the tent. She barges in fully knowing what they&#39;re doing.</p>
<p>Dave! (01:10:20.626)
And Ellie is just like, Oh, wait, cool, man. Hey, hey, how&#39;s it going?</p>
<p>Bryan! (01:10:23.856)
Yep. She basically urges them to stop and help her. She&#39;s like, you know, I&#39;m not really in a hurry, but you know, hurry up, I guess.</p>
<p>Dave! (01:10:35.754)
Then Ellie responds like every character. What&#39;s her face? Who&#39;s the one from scary movie? Anna Faris. She&#39;s like Anna Faris, like every Anna Faris character in scary movie.</p>
<p>Bryan! (01:10:42.128)
Oh, I think I got it first.</p>
<p>Bryan! (01:10:49.153)
Yep. So to go looking, she gets herself a horror movie flashlight and then gets into a horror movie truck to drive out into the wilderness. It&#39;s amazing.</p>
<p>Dave! (01:10:57.002)
Now I have to say, again, as a weird kid, I didn&#39;t go to camp, and I have absolutely never run a camp for gifted children at Thanksgiving. But if I were going to, I would make sure at least one vehicle works, because this movie has two vehicles in it. Neither one of them works. You don&#39;t seem like you&#39;re, you don&#39;t seem like you&#39;re well prepared for a gifted Thanksgiving camp.</p>
<p>Bryan! (01:11:17.749)
This is a catastrophe waiting to happen.</p>
<p>Bryan! (01:11:25.095)
Yep. So, and we get these two icons in the same scene where the truck doesn&#39;t start and the flash light is dead.</p>
<p>Dave! (01:11:31.178)
Now, I&#39;ve always wondered because the flashlight thing is a thing that I criticize films for a lot. And I think, well, is it possible that you could, like maybe it just isn&#39;t connecting because it&#39;s a pretty basic kind of feat of mechanical engineering. Like it&#39;s just a battery touches a thing. So maybe it could just bang on it. And then it&#39;s just like, no, that&#39;s not how flashlights, it&#39;s not how anything works. You can&#39;t just, you can&#39;t just touch a knife.</p>
<p>Bryan! (01:11:56.876)
Yeah, yeah. But like.</p>
<p>Dave! (01:12:00.758)
to a fucking car engine and make it start. You can&#39;t just bang on a thing. Now I get it. We wouldn&#39;t have a movie if you could, but like, come on. You&#39;re better than that, Madman. I don&#39;t know if you are actually.</p>
<p>Bryan! (01:12:07.932)
Yeah.</p>
<p>Bryan! (01:12:13.012)
Yeah, so let&#39;s see. She&#39;s out in the woods. She gets the thing started and gets off. And she&#39;s out in the woods. And Mars is stalking her. She sees a light off in the distance. She finds Dave&#39;s dropped lantern. And then she finds Dave. But then she does something that you don&#39;t often see in other horror movies. When she finds the body, she just sees his legs sort of over a log. And then any other movie.</p>
<p>they do that thing where they&#39;d be like, now come on Dave, stop playing around. But her initial instinct is, are you okay? And that&#39;s when she runs over and she.</p>
<p>Dave! (01:12:55.606)
Yeah, cause that&#39;s what you do as a normal person. Or I just be like, mm, this doesn&#39;t look good, I gotta go.</p>
<p>Bryan! (01:12:58.281)
Yeah.</p>
<p>Yeah, but what she finds is his decapitated body. And then the sheesh.</p>
<p>Dave! (01:13:05.642)
And then you hear that sweet Madman Mars love theme kicks in.</p>
<p>Bryan! (01:13:09.928)
Yep. And the body falls down next to a pretty good looking severed head prop. And I got, like I said, with some of the kills, they&#39;re pretty good. Some of the prop appliances also pretty good. Like that&#39;s a...</p>
<p>Dave! (01:13:26.346)
Yeah, the head, yes, the decapitated neck, not quite so much. That&#39;s got some real like Halloween, Hey Ride vibe there to it.</p>
<p>Bryan! (01:13:31.244)
Eh, not so much, yeah.</p>
<p>Bryan! (01:13:37.96)
Yeah, the thing with the heads always gets me because it feels like it&#39;s such a detailed part of the body. And it&#39;s like the thing that we always kind of like, you know, there&#39;s so much pattern in it that like we&#39;re sort of drawn to look at it. But like contemporaries at this time, like Tom Savini is a very talented effects artist, but his severed heads always looked like fucking shit. So like for this one at this time that was operating, I think the same year that like the Prowler came out, that&#39;s a pretty good looking head.</p>
<p>So points to Dippy. Good job.</p>
<p>Dave! (01:14:12.198)
When you make puppets in a barn in Vermont, you gotta get good at something, you don&#39;t have a lot of time on your hands.</p>
<p>Bryan! (01:14:17.916)
Yeah, so she hysterically runs back to the truck, tries to start it. And wouldn&#39;t you know, it doesn&#39;t start. So she gets out, opens up the hood. And while she&#39;s under it, Mars jumps on the hood. And her decapitated body slumps to the ground. That&#39;s probably not how that&#39;s going to work, no matter how heavy he is. But I&#39;ll take it.</p>
<p>Dave! (01:14:32.27)
Hmph.</p>
<p>Dave! (01:14:41.694)
And this is about where the movie starts to drag a little bit.</p>
<p>Bryan! (01:14:44.848)
little bit. So we get back.</p>
<p>Dave! (01:14:46.83)
Because there&#39;s like a big gap in my notes where I&#39;m just like, wait, when does this shit happen? Oh, it&#39;s not for like another 20 minutes.</p>
<p>Bryan! (01:14:53.undefined)
Yeah, because there is a bit, because this is like really a bit of a stretch up until the very end where, well, no, this is a bit that kind of leads into where Ellie has her scene in the kitchen. That was pretty good. And then we move on to sort of Betsy&#39;s stuff where like it&#39;s all pretty snappy and moves along. But yeah, this section is a little dense. Ellie and Bill find the truck and Ellie sees</p>
<p>Mars standing over Stacey&#39;s dead body and she screams Drawing his attention she runs to bill who does the next slasher will be blunder instead of going back to camp They go to the truck to see what it was that Ellie saw fucking</p>
<p>Dave! (01:15:35.323)
Yeah, because he&#39;s just like, well, let&#39;s just go back and see. And, you know, maybe we&#39;ll see if you saw what you really thought you saw. It&#39;s like, hey, man, I know she&#39;s an idiot, but you don&#39;t have to condescend to her like that.</p>
<p>Bryan! (01:15:48.892)
Rookie move, assholes. So they walk around the truck and they see every side of the truck except for the bloodstained front end. Bill Trotter?</p>
<p>Dave! (01:15:56.506)
Meanwhile, he&#39;s like every time they&#39;re on one side like madman Mars is creeping around the other side is again It&#39;s a very Scooby-Doo moment</p>
<p>Bryan! (01:16:01.984)
He&#39;s so, oh yeah. Yeah. And then he goes back around, like going the long way around to avoid the blood on the front end. They try to start it and it doesn&#39;t start. So he&#39;s like, okay, I&#39;m gonna get out and open up the hood. And he starts to do so, it sort of backs off in terror. And what we see is Stacy&#39;s head on the engine block. So he.</p>
<p>Dave! (01:16:24.854)
Oh, this is when he tries to start it and you hear this horrific squelching noise.</p>
<p>Bryan! (01:16:28.292)
Yes! Yes, like some part of her is like jammed up in the belts or something.</p>
<p>Dave! (01:16:34.098)
And I guess I&#39;ll give him... ..frops for that, but... ..ugh.</p>
<p>Bryan! (01:16:38.168)
I only noticed that this time.</p>
<p>Dave! (01:16:41.482)
Yeah, I&#39;ve seen this movie like six or seven times and-</p>
<p>Bryan! (01:16:45.552)
Yeah. So, uh, so he very quickly like, boom, like, ah, tries to like, get the head off of the block and he closes up the, the hood and it starts like miraculously, but as they&#39;re starting to like pull away, Mars comes in, pulls, pulls Bill out of the, out of the driver&#39;s seat through the window. And, uh, Ellie is, you know, screaming and she tries to take control of it, but the car, like the truck like rolls along and like smacks into a tree.</p>
<p>And then she gets out and sort of runs back to camp. But not before.</p>
<p>Dave! (01:17:16.726)
And this is where you get that real, she gets that real chilly energy where she&#39;s just like, oh God, oh no, oh God, oh no, oh God.</p>
<p>Bryan! (01:17:25.346)
Yeah. She looks back and she sees Mars holding Bill&#39;s body over his head, like fucking like Hulk Hogan or something like that. There&#39;s all these like popping and cracking sounds as he does it. But yeah, so he what Mars has been doing this whole time, and I haven&#39;t really remarked on it is every time he kills somebody, he drags them back to his house. And so that.</p>
<p>Dave! (01:17:31.168)
Ha!</p>
<p>Dave! (01:17:46.998)
Yeah, he kills them and drags off their bodies. It&#39;s literally in the song. That song- If you don&#39;t want to watch this movie, just listen to the song. It&#39;ll tell you all you need to know.</p>
<p>Bryan! (01:17:49.788)
Yeah, it&#39;s in the song. And so.</p>
<p>Bryan! (01:17:58.017)
It&#39;s everything you need to know. There&#39;s just no gore or terrible sex scene. So we get more of stupid fucking Richie in the forest, who I think at this point is like just pushing his way through branches and says like, where am I? It&#39;s like exactly-</p>
<p>Dave! (01:18:03.971)
terribly awesome.</p>
<p>Dave! (01:18:16.138)
Yeah, except it&#39;s all 80 yard. And so his mouth isn&#39;t moving, but you hear him being like, oh, oh shit, oh no. But it&#39;s like, he&#39;s clearly not speaking when you&#39;re hearing this.</p>
<p>Bryan! (01:18:25.438)
Yeah.</p>
<p>Bryan! (01:18:28.7)
Yeah, but nobody else seems to have any problem moving around the forest, but Richie can&#39;t get his fucking bearings. And what we see is he eventually gets it back to the house and he sees Mars dragging the body into the house. And so after that, Madman Mars like happily leaps back out into the forest, like bounding and jumping. And Richie goes in and goes down into the basement. He makes a terrible discovery.</p>
<p>that we don&#39;t see.</p>
<p>Dave! (01:18:59.21)
Honestly, I who gives a shit about Richie? Like I had forgotten all about it every time he comes up in the movie. It&#39;s just like this again because he does. It&#39;s the same fucking thing. Just loop it. It&#39;s the same scene over and over again.</p>
<p>Bryan! (01:19:04.916)
The fact...</p>
<p>The fact is that I&#39;m not going to be able to do it.</p>
<p>He brings... He brings nothing to the story whatsoever. And every time I see him, I&#39;m like, You motherfucker, this is your fault.</p>
<p>Dave! (01:19:18.806)
Well, it will come as no surprise to you then that this is the only film he&#39;s ever been in.</p>
<p>Bryan! (01:19:25.097)
Yep. So Ellie makes it back to camp, but so does Mars. They chase her into a kitchen. He handily chops his way through a door and then through another one. But Ellie manages to hide in the refrigerator, which I was told to never to do.</p>
<p>Dave! (01:19:34.958)
Mmph.</p>
<p>Dave! (01:19:40.874)
Ellie, if Punky Brewster has taught us anything, and it hasn&#39;t, it&#39;s that you never hide in the refrigerator.</p>
<p>Bryan! (01:19:51.196)
Yep, but it works. So in your face, voices of safety.</p>
<p>Dave! (01:19:53.002)
Well, it sorta works. It sorta works. She throws all the fucking food on the floor, and it&#39;s like, okay, I get that he&#39;s a mad man who lives in the woods, but... not a dummy.</p>
<p>Bryan! (01:20:03.196)
Yeah, and this is when she&#39;s in there, we get her perspective and we hear Mars outside throwing shit around and going like, Grr, grr. But then things quiet down and she emerges from the fridge. And this is probably the most legitimately tense moment in the movie because it gets, we get that kind of throbbing synth sting as she&#39;s walking back through the place.</p>
<p>She&#39;s about to like leave the cabin when an axe comes out of nowhere and strikes her in the chest. She falls back, she got fucking blood all over her and shit. And then we go outside and Betsy comes out and she looks into the cabin and sees Ellie&#39;s bloody body on the ground. So she runs back to the office. She calls Max and Bet-</p>
<p>Dave! (01:20:36.973)
Ah.</p>
<p>Dave! (01:20:49.304)
She grabs the phone and my note says she&#39;s finally calling the- wait no she&#39;s calling Max. Bitch! Call the police!</p>
<p>Bryan! (01:20:54.415)
EW</p>
<p>I know, I know, she begs him to come back, and then, against all horror movie instincts, she grabs the shotgun off the wall and loads it. This fucking gun, her handling of this firearm is so goddamn terrible. So-</p>
<p>Dave! (01:21:04.902)
Oh no. Oh no. Oh no.</p>
<p>Dave! (01:21:12.854)
This is the, this is the elephant gun in Tremors.</p>
<p>Bryan! (01:21:16.452)
It&#39;s yeah, it&#39;s a double-barreled shotgun. It&#39;s quite large. She goes back to the cabin where Ellie&#39;s body was And Mars presumably presses Ellie&#39;s head against the window, but she&#39;s still sort of alive at this scene But Betsy just gives her both fucking barrels shoots her right in the face</p>
<p>Dave! (01:21:29.22)
Ugh.</p>
<p>Dave! (01:21:34.906)
And it is, there are two things about this scene that are... remarkable. One is... when she shoots her in the face, I thought, good god, that is insult to injury. This is like the, of all these fucking dopey dumbass characters, this is like the one you kind of like, alright, you&#39;re an idiot, but you&#39;re also, yeah, I kind of like you. And she just gets her head fucking blown off after getting an axe in the chest. That&#39;s... not great. But also the look...</p>
<p>Bryan! (01:22:02.685)
Yep.</p>
<p>Dave! (01:22:04.662)
on Betsy&#39;s face when she fires the gun. And it&#39;s in the trailer too. It&#39;s in the movie and in the trailer, she has this look on her face, like she&#39;s just like, what was that? Like she&#39;s like, yeah, she&#39;s just sort of like a little bit stunned and a little jarred like, oh, but what happened?</p>
<p>Bryan! (01:22:15.269)
Whoops!</p>
<p>Bryan! (01:22:22.588)
Oh, I know, I know. Either that or like her mind is just blanked it out. Like I have just brutalized one of my friends with Buckshot. Like I am not gonna, that&#39;s, it&#39;s going to take therapy to bring this out. And in the meantime, I&#39;m going to do terrible things to myself.</p>
<p>Dave! (01:22:34.602)
or</p>
<p>Dave! (01:22:37.658)
She&#39;s just like, well, I got that creep show coming up. I maybe that&#39;ll change the trajectory of things. This isn&#39;t going well. Three more days, three more days.</p>
<p>Bryan! (01:22:46.573)
Yeah, so she then goes back. She ushers the kids onto the bus and manages to get down the road a ways. But Mars catches up and this is where we get that really funny scene where the headlights like cross the road. We see him like in the middle of the road and he kind of reacts like whoops, I&#39;m gonna get out of the way.</p>
<p>Dave! (01:23:06.858)
Yeah, it is a very like, ooh! Heh. Oh. Oh, you&#39;re further along than I thought you&#39;d be.</p>
<p>Bryan! (01:23:11.936)
But she they slow and they stop and he gets his hand in the in the door and they manage to close it they smash it repeatedly with a baseball bat until he goes away. And then.</p>
<p>Dave! (01:23:28.306)
say I will give them credit for this. This is one of very few camp movies that takes place at camp and actually has children in it. Because Friday the 13th, there are like fucking 37 of those movies. You don&#39;t actually see children in those movies until the seventh.</p>
<p>Bryan! (01:23:36.7)
Yeah, yeah, that&#39;s a thing.</p>
<p>Bryan! (01:23:45.86)
Yeah, yeah. I think really, I mean, like the burning was the first one to really kind of do that. But I mean, honestly, even in that one, like Fisher Stevens is the only person who looks like a kid in that.</p>
<p>Dave! (01:23:47.327)
Now.</p>
<p>Dave! (01:23:57.034)
And you want all of them to die.</p>
<p>Bryan! (01:23:59.16)
Oh yeah, they&#39;re the worst man, except for him. He&#39;s all right. But so at this point, she decides that this is horror movie blunder number three. I have to go back for the others.</p>
<p>Dave! (01:24:11.19)
Yeah, but also how does she know how to drive a bus? Could you drive a bus? I don&#39;t think I could.</p>
<p>Bryan! (01:24:13.989)
I don&#39;t know.</p>
<p>No, you have to have like a CDL for it. You gotta have like, train for that shit.</p>
<p>Dave! (01:24:19.982)
I mean, like, can you legally drive a bus? I mean, like, if you got on one, you&#39;re driving.</p>
<p>Bryan! (01:24:22.496)
No, no, I mean, but I mean, when you, when you like to get a CDL, like you gotta know how to drive the actual vehicle. It&#39;s not like driving a fucking car. It&#39;s like in, it&#39;s like in that scene in near dark where the guy like, where they&#39;re in the truck and the guy explains to him how to drive the truck because if you don&#39;t, you&#39;re gonna fucking crash and kill everybody. Like there&#39;s a whole like thing to it. Not to drive in a bus is like an 18 wheeler, but you know.</p>
<p>Dave! (01:24:27.426)
Like, can you go get a new job tomorrow?</p>
<p>Dave! (01:24:43.471)
It&#39;s not like driving a car either, I imagine.</p>
<p>Bryan! (01:24:45.456)
No, no, but the answer is probably not. I probably could not drive a bus.</p>
<p>Dave! (01:24:49.878)
But no, but not only does Galen Ross know how to drive a bus, this other kid, she was just like, take the wheel, Steve, I gotta go save the rest of everybody else. And the answer is Galen Ross, stay in that bus and drive the fuck out of here.</p>
<p>Bryan! (01:24:56.152)
Take the wheel.</p>
<p>Bryan! (01:25:03.76)
Oh, that&#39;s the thing. So as far as final girls go, she&#39;s pretty bad at this.</p>
<p>Dave! (01:25:08.758)
Well, she&#39;s not a final girl, as we&#39;re about to find out.</p>
<p>Bryan! (01:25:10.784)
That&#39;s what about to find out. She&#39;s the final girl to get killed. Yeah, so she stalks back to his house, blows off some of her last remaining shells in the shotgun. She&#39;s super unsafe with this. She shoots at the slightest provocation. It is logically consistent that she killed one of her friends conclusively with this gun. She&#39;s just firing at everything.</p>
<p>Dave! (01:25:14.218)
Yeah, this is final-ish.</p>
<p>Bryan! (01:25:41.06)
So Mars eventually.</p>
<p>Dave! (01:25:41.93)
Yeah, like, like if they weren&#39;t all already dead, she would definitely have killed them.</p>
<p>Bryan! (01:25:45.936)
Oh, she&#39;d finish him off. Yeah, so Mars gets the best of her while she&#39;s trying to reload the gun, drags her downstairs where they struggle a bit more, and he throws her on a... Oh, she tears like half her fucking face off. So they get down in the basement, he lifts her up, throws her on a meat hook. And she manages to like, she&#39;s got basically the whole time she&#39;s had this knife on her belt that&#39;s prominently featured in a few scenes. You could call it Chekov&#39;s belt knife, I suppose.</p>
<p>Dave! (01:25:54.666)
after he rips a gnarly hole in her face.</p>
<p>Bryan! (01:26:14.944)
but she gets it out.</p>
<p>Dave! (01:26:14.99)
It&#39;s not as good as Chekhov&#39;s axe, but it&#39;s something.</p>
<p>Bryan! (01:26:18.821)
I tried, but she gets to stab him once, he kind of flails and knocks a candle on the ground which lights some hay on fire.</p>
<p>Dave! (01:26:27.37)
Yeah, because the thing you haven&#39;t mentioned is that Madman Morris&#39;s house is not only lit with shitloads of votive candles, it is also full of hay. And just like, imagine building a house. He&#39;s like a human mouse. He&#39;s built a house out of absolutely everything flammable you could find. And she stabs him like, you know, in the shoulder or whatever.</p>
<p>Bryan! (01:26:35.876)
Oh yeah, it&#39;s like something out of an Anne Rice novel.</p>
<p>Bryan! (01:26:47.25)
Yep.</p>
<p>Dave! (01:26:52.734)
And the only reason these people all die is because Mars knocks over one of his 1800 candles and it hits all of his hay. Up they go.</p>
<p>Bryan! (01:27:03.031)
Yeah, the whole place goes up. And I can&#39;t remember, do we actually see him sort of escape into the night?</p>
<p>Dave! (01:27:09.85)
No, I think he dies.</p>
<p>Bryan! (01:27:11.424)
Oh, okay. Because the implication here is that he kind of gets away. And so Max returns and he runs into a catatonic Richie on the road who announces that Mars is real as we flash over what he saw in the basement, which is all of his friends on Meat Hooks.</p>
<p>Dave! (01:27:32.318)
And my note says, oh Max, you have a lot of explaining to do.</p>
<p>Bryan! (01:27:36.256)
So much explaining to do insurance nightmare. He&#39;s gonna</p>
<p>Dave! (01:27:38.762)
I can think of six sets of parents that are gonna be really upset with you.</p>
<p>Bryan! (01:27:43.53)
He&#39;s like Mel in Sleepaway Camp where he&#39;s like, oh, these insurance companies are going to kill me. So this is...</p>
<p>Dave! (01:27:45.777)
Really?</p>
<p>Dave! (01:27:49.398)
Ooh! There will not be a Thanksgiving gifted camp next year. And tell you that much.</p>
<p>Bryan! (01:27:56.606)
No, this experiment has failed catastrophically. So this is a really strange twist for slashers in that the bad guy wins. At this point, I&#39;m going to go ahead and start the video.</p>
<p>Dave! (01:28:04.842)
And I, I for one love when the bad guy wins. I wouldn&#39;t love it if it happened all the time.</p>
<p>Bryan! (01:28:10.676)
I think we&#39;ve talked about this a little bit before in other episodes, but I definitely do appreciate a horror movie that goes there. The Babadook has an ending like that. I think we talked about where the house wins in The House on Haunted Hill.</p>
<p>Dave! (01:28:28.162)
Like I don&#39;t care, like not so much for this, not when it&#39;s just like, and then Jason Voorhees gets away or like, whatever, I don&#39;t give a fuck about that. Like that&#39;s because that&#39;s so, it&#39;s so obviously contrived so we can get more sequels. But I do like it when it leaves you with that kind of unsettled, kind of gross, like my favorite film, Black Christmas, where at the very end it&#39;s like, no, but he&#39;s still alive and he&#39;s still in the house.</p>
<p>Bryan! (01:28:35.912)
Yeah, yeah.</p>
<p>Bryan! (01:28:55.172)
Yeah, yeah. It&#39;s a very, because we don&#39;t get it very often and because I imagine like a lot of filmmakers just don&#39;t want to make, like don&#39;t want to leave the audiences feeling bad. They feel like obliged to have a sort of triumphant moment at the end, but like, I think that it&#39;s a very sweet twist of expectations when you let the bad guy win. Cause like just about everybody dies in this movie.</p>
<p>And like this is the thing when this movie came out there weren&#39;t a lot of slasher movies to really sort of lay down the tracks and sort of establish the tropes So like they were kind of free to do anything and up to this point</p>
<p>Dave! (01:29:32.966)
Now, here&#39;s what I love about this, though. So I have said on the record that I love this era. This is probably my favorite era of horror movies before, like between, let&#39;s say Black Christmas. So was that 74? Between that and I&#39;m going to say 82. That&#39;s my favorite era, because this is it&#39;s like just after</p>
<p>Bryan! (01:29:48.704)
Mm, 74.</p>
<p>Bryan! (01:29:54.57)
Mm-hmm.</p>
<p>Dave! (01:30:00.266)
You get a shitload of knockoffs, but you also it&#39;s all before this dumbass trope business, which I have a lot of problems with anyway, because I think a lot of those are applied to films again, far after the fact for like when they&#39;re like, well, clearly this is, you know, the slut has to die. And it&#39;s like, I don&#39;t really think that&#39;s what&#39;s happening here. But sure, because I find all that really tiring as well. I love this thing where it&#39;s just like there is no fucking road map.</p>
<p>Bryan! (01:30:12.168)
Mm.</p>
<p>Bryan! (01:30:23.264)
Mm-hmm.</p>
<p>Dave! (01:30:27.326)
Everybody is just trying to make money in any way they can. So they are doing the wildest shit. And you get so many movies that most of them are like this. They do not make any sense at all. They&#39;re dumb as shit. They have pretty small budgets and no recognizable actors, but they have so much goddamn charm and heart that you can&#39;t help but love watching them because they&#39;re so fucking stupid. And most of the time they know that they&#39;re stupid.</p>
<p>Bryan! (01:30:50.961)
Yeah.</p>
<p>Bryan! (01:30:54.728)
Yeah.</p>
<p>Dave! (01:30:55.806)
And I like that. I like that they&#39;re not like, hey, what about this? Wink, wink, nudge. Remember when that happened in the other movies? Like, no, fuck off.</p>
<p>Bryan! (01:31:03.388)
Yeah. So we roll credits over the Madman Mars theme song. Not Too Many Killers. Yep, Not Too Many Killers have their own theme song. I really do wish they had more. I think Freddy Krueger is the only other one.</p>
<p>Dave! (01:31:09.464)
This is the dance jam.</p>
<p>Dave! (01:31:16.606)
I mean, you could argue that Michael Myers, Michael Myers has his own.</p>
<p>Bryan! (01:31:18.524)
Oh, Jason. Well, he&#39;s got a, yeah, he&#39;s got a theme. Alice Cooper did the Jason Bourne&#39;s one. No, it&#39;s the best. It&#39;s the man behind the mask. Yep. So yeah, that is it. Madman. It is a very, very unusual movie. It&#39;s, thanks. I know, I know. We probably could have saved this one for Thanksgiving to sort of double up with our Thanksgiving feature, but.</p>
<p>Dave! (01:31:23.562)
And it&#39;s also the sweetest thing. Oh, he did the man behind the mask, right?</p>
<p>Dave! (01:31:37.346)
Thanksgiving camp. Thanksgiving gifted camp.</p>
<p>Dave! (01:31:46.514)
It did, it did occur to me after, I&#39;m like, well this actually isn&#39;t a summer camp movie, it&#39;s a Thanksgiving gifted camp movie, and I don&#39;t know where you put that on the calendar.</p>
<p>Bryan! (01:31:52.892)
Yep.</p>
<p>Bryan! (01:31:56.596)
Thanksgiving double feature with uh, fuck uh, blood rage. Oh, we&#39;re doing blood rage, but um, yeah, so what are we doing next?</p>
<p>Dave! (01:32:01.008)
Oh lord, we&#39;re gonna get to that.</p>
<p>Dave! (01:32:07.062)
Well, we are going to be staying in Long Island for the next one in what is probably one of the most notorious horror films of all time, one of the most divisive horror films of all time, Sleepaway Camp. And wow, do I love that movie and holy shit, is it wild.</p>
<p>Bryan! (01:32:20.728)
Yes! Oh, God.</p>
<p>Bryan! (01:32:27.128)
My God, I have been looking, since we started this fucking podcast, I have been looking forward to this episode. Just, I could do 90 minutes on Aunt Martha alone.</p>
<p>Dave! (01:32:39.198)
And it&#39;s, and everyone&#39;s just gonna be like, oh, because of the twist ending. I don&#39;t give a fuck about the ending. It&#39;s everything else in that movie that&#39;s really weird.</p>
<p>Bryan! (01:32:43.775)
No!</p>
<p>Bryan! (01:32:47.22)
Yeah, that&#39;s the thing is everything in that movie lives in the shadow of the ending. That movie is fucked up from start to finish in so many weird ways that just nobody talks about. So, oh, it&#39;s so great. Oh, I can&#39;t wait. So, yeah, see us back here in two weeks when we give you a sleepaway camp.</p>
<p>Dave! (01:32:58.978)
but it&#39;s also really awesome.</p>
<p>Dave! (01:33:10.69)
Woof.</p>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/sleepaway-camp">Next Episode</Link></li>
                        <li><Link to="/episodes/jaws">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)